import * as validators from '@vuelidate/validators'
import { i18n } from '@/locales/i18n'

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators

// Create your i18n message instance. Used for vue-i18n@9
//const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })
// for vue-i18n@8
 const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required)
// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
export const requiredUnless = withI18nMessage(validators.requiredUnless, { withArguments: true })
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const minValue = withI18nMessage(validators.minValue, { withArguments: true })
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })
export const between = withI18nMessage(validators.between, { withArguments: true })
export const alpha = withI18nMessage(validators.alpha, { withArguments: true })
export const alphaNum = withI18nMessage(validators.alphaNum, { withArguments: true })
export const numeric = withI18nMessage(validators.numeric, { withArguments: true })
export const integer = withI18nMessage(validators.integer, { withArguments: true })
export const decimal = withI18nMessage(validators.decimal, { withArguments: true })
export const email = withI18nMessage(validators.email, { withArguments: true })
export const ipAddress = withI18nMessage(validators.ipAddress, { withArguments: true })
export const macAddress = withI18nMessage(validators.macAddress, { withArguments: true })
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
export const url = withI18nMessage(validators.url, { withArguments: true })
export const or = withI18nMessage(validators.or, { withArguments: true })
export const and = withI18nMessage(validators.and, { withArguments: true })
export const not = withI18nMessage(validators.not, { withArguments: true })
export const helpers = validators.helpers
// or you can provide the param at definition, statically
//export const maxLength = withI18nMessage(validators.maxLength(10))