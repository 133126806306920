const Helper = {
  filterFloatingProblem(floating_number) {
    return (floating_number * 100) / 100
  },
  randomString(char_quantity) {
    let text = "";
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    // Aseguramos que el primer carácter sea una letra
    text += letters.charAt(Math.floor(Math.random() * letters.length));

    // Generamos el resto de los caracteres
    for (let i = 1; i < char_quantity; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  },

};

export default Helper;