<template>
    <div>
        <v-app-bar class="mx-auto" v-if="is_logged" flat color="white" max-width="1500">
            <RelatedAccounts class="ml-3" />
            <v-spacer></v-spacer>
            <WindowTooltip eager icon="mdi-bell" :has_badge="notifications > 0" :content="notifications">
                <Notifications @unread="unreadNotifications" class="mt-5" />
            </WindowTooltip>
            <WindowTooltip icon="mdi-account" icon_color="black" :tooltipProps="{ maxWidth: 500 }">
                <ProfileView />
            </WindowTooltip>
        </v-app-bar>


        <div class="sidebar" :style="cssVars">
            <loader :loading="loading"></loader>
            <div class="icon" style="background-color: white; padding: 10px 10px 2px 20px;">
                <router-link to="/"><img :src="require('@/assets/logo_icon.png')" /></router-link>
            </div>
            <div class="my-auto" style="margin: 6px 14px 0 14px;">
                <ul class="nav-list" style="overflow: visible;">
                    <li>
                        <a @click="changeFullScreen()"><i :class="fullscreen ? 'bx-exit-fullscreen' : 'bx-fullscreen'"
                                class="bx"></i>
                            <span class="tooltip">{{ $t('b125') }}</span>
                        </a>
                    </li>
                    <span v-for="(menuItem, index) in items_menu" :key="index">
                        <li>
                            <router-link :to="{ name: menuItem.link }">
                                <i class="bx" :class="menuItem.icon || 'bx-square-rounded'" />
                                <span class="links_name">{{ menuItem.name }}</span>
                            </router-link>
                            <span class="tooltip">{{ menuItem.tooltip || menuItem.name }}</span>
                        </li>
                    </span>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { Profile } from "@/services/Profile";
import RelatedAccounts from '@/components/index/RelatedAccounts.vue';
import Notifications from "@/components/show/Notifications";
import WindowTooltip from "@/components/page/WindowTooltip";
import ProfileView from "@/views/Profile.vue"
export default {
    name: 'SidebarMenu',
    components: {
        RelatedAccounts,
        Notifications,
        ProfileView,
        WindowTooltip,
    },
    data() {
        return {
            fullscreen: false,
            items_menu: [],
            is_logged: false,
            profile: {},
            loading: false,
            notifications: 0,
        }
    },
    beforeMount() {
        window.document.body.style.paddingLeft = '78px'
        this.profile = new Profile();
        this.items_menu = this.profile.getItemsMenu();
        this.is_logged = this.profile.isLogged();
    },
    computed: {
        cssVars() {
            return {
                // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
                /*'--bg-color': "#002B5A",
                '--secondary-color': "#1d1b31",
                '--home-section-color': "#e4e9f7",
                '--logo-title-color': "#fff",
                '--icons-color': "#fff",
                '--items-tooltip-color': "#e4e9f7",
                '--serach-input-text-color': "#fff",
                '--menu-items-hover-color': "#fff",
                '--menu-items-text-color': "#fff",
                '--menu-footer-text-color': "#fff",*/
                '--bg-color': "#f4f6f9",
                '--secondary-color': "#1d1b31",
                '--home-section-color': "#e4e9f7",
                '--logo-title-color': "#fff",
                '--icons-color': "#000",
                '--items-tooltip-color': "#e4e9f7",
                '--serach-input-text-color': "#fff",
                '--menu-items-hover-color': "#222",
                '--menu-items-text-color': "#222",
                '--menu-footer-text-color': "#fff",
            }
        },
    },
    methods: {
        isAdmin() {
            return this.profile.isAdmin()
        },
        login() {
            this.$router.push("/login");
        },

        changeFullScreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
                this.fullscreen = true
            } else if (document.exitFullscreen) {
                document.exitFullscreen();
                this.fullscreen = false
            }
        },
        unreadNotifications(quantity) {
            this.notifications = quantity;
        },
    }
}
</script>

<style>
/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    transition: all 0.5s ease;
}

.menu-logo {
    width: 200px !important;
    margin: 0px;
}

.sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    min-height: min-content;
    /* overflow-y: auto; */
    width: 78px;
    background: var(--bg-color);
    /* padding: 6px 14px 0 14px; */
    z-index: 99;
    transition: all 0.5s ease;
}

.sidebar .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
    color: var(--logo-title-color);
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
    text-align: right;
}

.sidebar i {
    color: var(--icons-color);
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
}

.sidebar .nav-list {
    margin-top: 20px;
    /* margin-bottom: 60px; */
    /* height: 100%; */
    /* min-height: min-content; */
}

.nav-list {
    padding-left: 0px !important;
}

.sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
}

.sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: var(--items-tooltip-color);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
}

.sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
}

.sidebar input {
    font-size: 15px;
    color: var(--serach-input-text-color);
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: var(--secondary-color);
}

.sidebar .bx-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
    background: var(--secondary-color);
    color: var(--icons-color);
}

.sidebar .bx-search:hover {
    background: var(--menu-items-hover-color);
    color: var(--bg-color);
}

.sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: var(--bg-color);
}

.sidebar li a:hover {
    background: var(--menu-items-hover-color);
}

.links_name {
    padding-top: 15px;
}

.sidebar li a .links_name {
    color: var(--menu-items-text-color);
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
    transition: all 0.5s ease;
    color: var(--bg-color);
}

.sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
}

.sidebar div.profile {
    position: relative;
    height: 80px;
    width: 78px;
    /* left: 0;
      bottom: 0; */
    padding: 10px 14px;
    background: var(--secondary-color);
    transition: all 0.5s ease;
    overflow: hidden;
}

.sidebar div .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.sidebar div img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 6px;
    /*margin-right: 10px;*/
}

.sidebar div.profile .name,
.sidebar div.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: var(--menu-footer-text-color);
    white-space: nowrap;
    margin-top: 10px;
}

.sidebar div.profile .job {
    font-size: 12px;
}

.login_buttons:hover {
    color: red;
    cursor: pointer;
}

.home-section {
    position: relative;
    background: var(--home-section-color);
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
}

.home-section .text {
    display: inline-block;
    color: var(--bg-color);
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
}

.my-scroll-active {
    overflow-y: auto;
}

#my-scroll {
    overflow-y: unset;
    height: calc(100% - 60px);
}

#my-scroll::-webkit-scrollbar {
    display: none;
    /* background-color: rgba(255, 255, 255, 0.2); 
      width: 10px;
      border-radius:5px  */
}

/* #my-scroll::-webkit-scrollbar-thumb{
      background-color: red;
      border-radius:5px 
    }
    #my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
      display:none;
    }
    #my-scroll::-webkit-scrollbar-button:vertical:end:increment{
      display:none;
    } */
@media (max-width: 420px) {
    .sidebar li .tooltip {
        display: none;
    }
}
</style>