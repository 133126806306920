import { Request } from "./Request";
import store from "@/store";
import { ProfileInterface } from "./interfaces/ProfileInterface";
import { i18n } from '@/locales/i18n'

class ProfileAbstraction extends ProfileInterface {
  #profile_type_name = "profile_type"; //no debe cambiar, STATIC
  #profile_no_logged = "no_logged"; //no debe cambiar, STATIC
  //no debe cambiar, STATIC
  #profiles = ["user", "worker", "distributor", "super-admin"];
  constructor() {
    super();
    if (this.constructor == "ProfileAbstraction") {
      throw new Error("Abstract class cannot be instantiate");
    }
  }

  getItemsMenu() {
    if (this.isLogged()) {
      switch (this.profile_type) {
        case "user":
          return [
            { link: "Home", tooltip: i18n.t("Home"), icon: "bx-home", mdi_icon: "mdi-home", name: i18n.t("Home") },
            { link: "Bookings", tooltip: i18n.t("bookings"), icon: "bx-book-content", mdi_icon: "mdi-book-open-outline", name: i18n.t("bookings") },
            //{ link: "Profile", tooltip: i18n.t("profile"), icon: "bx-user", mdi_icon: "mdi-account", name: i18n.t("profile") },
          ]
        case "worker":
          if (this.isAdmin()) {
            return [
              { link: "Home", tooltip: i18n.t("Home"), icon: "bx-home", mdi_icon: "mdi-home", name: i18n.t("Home") },
              { link: "Clients", tooltip: i18n.t("a72"), icon: "bxs-user-detail", mdi_icon: "mdi-folder-account", name: i18n.t("a72") },
              { link: "ManagePort", tooltip: i18n.t("bookings"), icon: "bx-book-content", mdi_icon: "mdi-book-open-outline", name: i18n.t("bookings") },
              { link: "WorkerTasks", tooltip: i18n.t("b100"), icon: "bx-calendar-event", mdi_icon: "mdi-calendar-outline", name: i18n.t("b100") },
              //{ link: "PortMap", tooltip: i18n.t("map"), icon: "bx-map-alt" , mdi_icon: "mdi-view-list", name: i18n.t("map") },
              { link: "Incidences", tooltip: i18n.t("b195"), icon: "bx-error-circle", mdi_icon: "mdi-alert-circle-outline,", name: i18n.t("b195") },
              { link: "InvoiceSettings", tooltip: i18n.t("b199"), icon: "bx-receipt", mdi_icon: "mdi-invoice-text-outline,", name: i18n.t("b199") },
              //{ link: "Settings", tooltip: i18n.t("settings"), icon: "bx-cog", mdi_icon: "mdi-account", name: i18n.t("settings") },
              //{ link: "Profile", tooltip: i18n.t("profile"), icon: "bx-user", mdi_icon: "mdi-account", name: i18n.t("profile") },
              { link: "Mailing", tooltip: i18n.t("Email"), icon: "bx-envelope", mdi_icon: "mdi-chat", name: i18n.t("Email") },
            ]
          } else if (this.isManagement()) {
            return [
              { link: "Home", tooltip: i18n.t("Home"), icon: "bx-home", mdi_icon: "mdi-home", name: i18n.t("Home") },
              { link: "Clients", tooltip: i18n.t("a72"), icon: "bxs-user-detail", mdi_icon: "mdi-folder-account", name: i18n.t("a72") },
              { link: "ManagePort", tooltip: i18n.t("bookings"), icon: "bx-book-content", mdi_icon: "mdi-book-open-outline", name: i18n.t("bookings") },
              { link: "WorkerTasks", tooltip: i18n.t("b100"), icon: "bx-calendar-event", mdi_icon: "mdi-calendar-outline", name: i18n.t("b100") },
              { link: "InvoiceSettings", tooltip: i18n.t("b199"), icon: "bx-receipt", mdi_icon: "mdi-invoice-text-outline,", name: i18n.t("b199") },
              //{ link: "PortMap", tooltip: i18n.t("map"), icon: "bx-map-alt" , mdi_icon: "mdi-view-list", name: i18n.t("map") },
              { link: "Incidences", tooltip: i18n.t("b195"), icon: "bx-error-circle", mdi_icon: "mdi-alert-circle-outline,", name: i18n.t("b195") },
              //{ link: "Profile", tooltip: i18n.t("profile"), icon: "bx-user", mdi_icon: "mdi-account", name: i18n.t("profile") },
            ]
          } else {
            return [
              { link: "Home", tooltip: i18n.t("Home"), icon: "bx-home", mdi_icon: "mdi-home", name: i18n.t("Home") },
              { link: "Clients", tooltip: i18n.t("a72"), icon: "bxs-user-detail", mdi_icon: "mdi-folder-account", name: i18n.t("a72") },
              { link: "ManagePort", tooltip: i18n.t("bookings"), icon: "bx-book-content", mdi_icon: "mdi-book-open-outline", name: i18n.t("bookings") },
              { link: "WorkerTasks", tooltip: i18n.t("b100"), icon: "bx-calendar-event", mdi_icon: "mdi-calendar-outline", name: i18n.t("b100") },
              //{ link: "PortMap", tooltip: i18n.t("map"), icon: "bx-map-alt" , mdi_icon: "mdi-view-list", name: i18n.t("map") },
              { link: "Incidences", tooltip: i18n.t("b195"), icon: "bx-error-circle", mdi_icon: "mdi-alert-circle-outline,", name: i18n.t("b195") },
              //{ link: "Profile", tooltip: i18n.t("profile"), icon: "bx-user", mdi_icon: "mdi-account", name: i18n.t("profile") },
            ]
          }
        default:
          break;
      }
    }

    return [
      { link: "Home", tooltip: i18n.t("Home"), icon: "bx-home", mdi_icon: "mdi-home", name: i18n.t("Home") },
      { link: "Login", tooltip: i18n.t("Login"), icon: "bx-log-in", mdi_icon: "mdi-login-variant", name: i18n.t("Login") },
    ];
  }

  getAllProfiles() {
    return this.#profiles;
  }

  isLogged() {
    return store.getters.logged;
  }

  get token() {
    return store.getters.token;
  }

  set token(token) {
    store.dispatch("registerAction", token);
  }

  /**
   * Devuleve el ultimo tipo de profile utilizado
   */
  get last_profile_type() {
    var p = store.getters.last_profile_type;
    if (this.#profiles.indexOf(p) > -1) {
      return p;
    }

    return "user";
  }

  /**
   * Devuleve el tipo de profile
   */
  get profile_type() {
    return store.getters.profile_type;
  }

  set profile_type(type) {
    if (!this.isLogged) {
      type = type.toLowerCase();
      if (!this.#profiles.includes(type)) {
        type = this.profile_no_logged;
      }
      store.dispatch("changeProfileType", type);
    }
  }
  /**
   * Actualiza solo los campos enviados del perfil en local
   * @param {Profile} profile
   */
  set local_profile(profile) {
    //actualiza solo los campos enviados
    var savedProfile = store.getters.profile || {};
    //merge del segundo con el primero
    Object.assign(savedProfile, profile);
    store.dispatch("updateProfileAction", savedProfile);
  }

  get local_profile() {
    return store.getters.profile;
  }

  isAdmin() {
    return (this.local_profile.permissions && this.local_profile.permissions.includes("admin"))
  }

  isManagement() {
    return (this.local_profile.permissions && this.local_profile.permissions.includes("management"))
  }

  workerIsAdmin(worker) {
    return (worker.permissions && worker.permissions.includes("admin"))
  }

  getProfileParam(param_name) {
    var profile = this.local_profile;
    if (!profile || typeof profile[param_name] === "undefined") {
      return "";
    }

    return profile[param_name];
  }

  /**
   *
   * @param {EmailParams} emailParams
   */
  async changeEmail(emailParams) {
    let url = this.profile_type + "/email";
    let request = new Request("PUT", url, {}, emailParams);
    return request.send().then(() => {
      this.local_profile = { email: emailParams.email };
    });
  }

  /**
   *
   * @param {PasswordParams} passwordParams
   */
  async changePassword(passwordParams) {
    let url = this.profile_type + "/password";
    let request = new Request("PUT", url, {}, passwordParams);
    return request.send().then((res) => {
      return res.data;
    });
  }

  /**
   *
   * @param {Profile} profile
   */
  async updateServerProfile(profile) {
    let url = this.profile_type;
    let request = new Request("PUT", url, {}, profile);
    return request.send().then((res) => {
      this.local_profile = res.data;
      return res.data;
    });
  }

  /**
   *
   * @param {Profile} profile
   */
  async getServerProfile() {
    let url = this.profile_type + "/profile";
    let request = new Request("GET", url);
    return request.send().then((res) => {
      this.local_profile = res.data;
      return res.data;
    });
  }
}

export { ProfileAbstraction };
