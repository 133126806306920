<template>
  <v-app>
    <Sidebar />
    <v-main class="bg-gray">
      <router-view />
    </v-main>
    <!--Footer /-->
  </v-app>
</template>

<script>
import Sidebar from "@/components/page/SideBar.vue";
//import Footer from "@/components/page/Footer.vue";
export default {
  name: 'App',
  components: {
    Sidebar,
    //Footer,
  },
  data: () => ({
    fab: false,
  }),
  beforeMount() {
    this.$vuetify.theme.themes.light.primary = '#002B5A'
    this.$vuetify.theme.themes.light.secondary = '#0B8FA5'
    this.$vuetify.theme.themes.light.accent = '#1D1B31'
  },

};
</script>
