<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <ChangeProfile />
          <v-divider />
        </v-col>
        <v-col cols="12">
          <ChangeEmail />
          <v-divider />
        </v-col>
        <v-col cols="12">
          <ChangePassword />
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <span class="ml-2 mb-2">
        <LanguageFlag />
      </span>
      <v-btn text to="Settings" v-if="isAdmin()"><v-icon>mdi-cog</v-icon></v-btn>
      <v-spacer />
      <v-btn text @click="logout"><v-icon>mdi-logout</v-icon></v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { Auth } from "@/services/Auth";
import { Profile } from "@/services/Profile";
import LanguageFlag from "@/components/page/LanguageFlag.vue";
import ChangeProfile from "@/components/profile/ChangeProfile.vue";
import ChangeEmail from "@/components/profile/ChangeEmail.vue";
import ChangePassword from "@/components/profile/ChangePassword.vue";
export default {
  name: "Profile",
  components: {
    ChangeProfile,
    ChangeEmail,
    ChangePassword,
    LanguageFlag,
  },
  data() {
    return {
      showProfile: false,
      showPassword: false,
      showEmail: false,
      profile: new Profile()
    };
  },
  methods: {
    isAdmin() {
      return this.profile.isAdmin()
    },
    logout() {
      this.loading = true;
      var auth = new Auth(this.profile.last_profile_type);
      auth.logout().finally(() => {
        this.loading = false;
      });
    },
  }
};
</script>
