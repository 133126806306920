<template>
  <v-card flat>
    <v-card-title class="d-flex justify-space-between">{{ email }} <v-btn color="green" icon
        @click="show_form = !show_form"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-card-title>
    <v-card-text v-show="show_form">
      <AlertMessage ref="my_alert" />
      <v-row dense>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
            :label="$t('Your current Password')" @click:append="show = !show" :error-messages="showErrors('password')"
            @blur="v$.user.password.$touch()"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.email" type="text" :label="$t('New email')"
            :error-messages="showErrors('email')" @blur="v$.user.email.$touch()">
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.email_confirmation" type="text"
            :label="$t('Repeat new email')" :error-messages="showErrors('email_confirmation')"
            @blur="v$.user.email_confirmation.$touch()"></v-text-field>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn :disabled="v$.$invalid == 1 || loading" @click="changeEmail()" color="orange" class="white--text"
            :loading="loading">
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>


</template>
<script>
import { Profile } from "@/services/Profile";
//import AlertMessage from "@/components/page/AlertMessage.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "@/locales/i18n-validators";
export default {
  name: "ChangeEmail",
  components: {
    //AlertMessage,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      user: {
        email: { required, email },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(40),
        },
        email_confirmation: { required, sameAs: sameAs(this.user.email) },
      },
    };
  },
  data() {
    return {
      show: false,
      show_form: false,
      user: {
        email: "",
        password: "",
        email_confirmation: "",
      },
      loading: false,
      email: new Profile().getProfileParam('email'),
    };
  },
  methods: {
    showErrors(name) {
      if (this.v$.user[name].$error) {
        return this.v$.user[name].$errors[0].$message;
      }
    },
    changeEmail() {
      this.loading = true;
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        var p = new Profile();
        p.changeEmail(this.user)
          .then((res) => {
            this.$refs.my_alert.showSuccess(res, this.$t('Email changed'));
            this.$router.push({
              name: "EmailConfirmation",
              params: { email: this.user.email },
            });
          })
          .catch((error) => {
            this.$refs.my_alert.showError(error, "");
          })
          .finally((this.loading = false));
      }
    },
  },
};
</script>
