<template>
  <v-card flat>
    <v-card-title class="d-flex justify-space-between">{{ user.name + ' ' + user.lastname }} <v-btn color="green" icon
        @click="show_form = !show_form"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle>
      + {{ getPrefix(user.country_phone_iso2) + ' ' + user.phone }}
    </v-card-subtitle>
    <v-card-text v-show="show_form">
      <AlertMessage ref="my_alert" />
      <v-row dense>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.name" type="text" :label="$t('Name')"
            :error-messages="showErrors('name')" @blur="v$.user.name.$touch()"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.lastname" type="text" :label="$t('Lastname')"
            :error-messages="showErrors('lastname')" @blur="v$.user.lastname.$touch()"></v-text-field>
        </v-col>
        <v-col cols="4">
          <SelectNations :show_flags="true" @selected_nation="setNation" label=""
            :nation_default="user.country_phone_iso2" :error_message="showErrors('country_phone_iso2')" />
        </v-col>
        <v-col cols="8">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.phone" type="text" :label="$t('Phone')"
            :error-messages="showErrors('phone')" @blur="v$.user.phone.$touch()">
          </v-text-field>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn :disabled="v$.$invalid == 1 || loading" @click="changeProfile()" color="orange" class="white--text"
            :loading="loading">
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { Profile } from "@/services/Profile";
import SelectNations from "@/components/inputs/SelectNations.vue";
import { Nation } from "@/services/Nation";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@/locales/i18n-validators";

const phoneRule = helpers.regex(/^\d{7,12}$/);
export default {
  name: "ChangeProfile",
  components: {
    SelectNations,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      user: {
        name: { required, minLength: minLength(2), maxLength: maxLength(40) },
        lastname: { required, minLength: minLength(2), maxLength: maxLength(80) },
        country_phone_iso2: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(2),
        },
        phone: { phoneRule },
      },
    };
  },
  beforeMount() {
    var profile = new Profile();
    this.user.name = profile.local_profile.name;
    this.user.lastname = profile.local_profile.lastname;
    this.user.country_phone_iso2 = profile.local_profile.country_phone_iso2 ? profile.local_profile.country_phone_iso2 : "es";
    this.user.phone = profile.local_profile.phone;
  },
  data() {
    return {
      user: {
        name: "",
        lastname: "",
        country_phone_iso2: "es",
        phone: "",
      },
      loading: false,
      show_form: false,
    };
  },
  methods: {
    showErrors(name) {
      if (name === "phone" && this.v$.user[name].$error) {
        return this.$t('phone_error');
      }
      if (this.v$.user[name].$error) {
        return this.v$.user[name].$errors[0].$message;
      }
    },
    changeProfile() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.loading = true;
        var p = new Profile();
        p.updateServerProfile(this.user)
          .then((res) => {
            this.$refs.my_alert.showSuccess(res, this.$t('Successful profile change'));
          })
          .catch((error) => {
            this.$refs.my_alert.showError(error, "");
          })
          .finally((this.loading = false));
      }
    },
    setNation(val) {
      this.user.country_phone_iso2 = val;
    },
    getPrefix(iso_2) {
      const nation = new Nation().getNationFromIso2(iso_2)
      return nation ? nation.calling_code : "";
    },
  },
};
</script>
