<template>
    <div>
        <v-card max-width="1000" min-width="800" min-height="400" max-height="1000" class="pa-5">
            <v-card-title>{{ $t('b95') }}</v-card-title>
            <v-card-actions>
                <v-row>
                    <v-col cols="6">
                        <v-text-field class="mr-6" v-bind="this.$textFieldProps" v-model="search"
                            append-icon="mdi-magnify" :label="$t('Search')" @input="searchField"
                            clearable></v-text-field>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-btn color="orange" text @click="allRead()">{{ $t('b227') }}</v-btn>
                    </v-col>
                </v-row>


            </v-card-actions>
            <v-card-text>
                <v-data-table :items-per-page="itemsPerPage" :headers="headers" :items="notifications"
                    :server-items-length="total" :loading="loading" @update:page="handlePageChange"
                    :footer-props="footerProps">
                    <template v-slot:item="{ item }">
                        <tr class="my-5" :class="{ 'unread': item.read_at === null }">
                            <td>{{ item.text }}</td>
                            <td>
                                <v-btn text v-model="item.read_at" @click="confirmed(item)"
                                    :color="item.read_at ? 'primary' : 'orange'" class="mr-2">{{ item.read_at ?
                                        $t('b97')
                                        : $t('b98') }}</v-btn> {{ getTextDate(item.read_at) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <ButtonDialog :dialog="confirm_dialog" :title="$t('a78')" confirmation @cancel="confirm_dialog = false"
            @confirm="confirmed" />
    </div>
</template>
<script>
import { WorkerNotification } from '@/services/WorkerNotification';
import ButtonDialog from '@/components/page/ButtonDialog';
import DateHelper from '@/services/DateHelper';
export default {
    name: "WorkerNotification",
    components: { ButtonDialog },
    data() {
        return {
            search: "",
            loading: false,
            confirm_dialog: false,
            item_to_confirm: {},
            notifications: [],
            notificationService: new WorkerNotification(),
            headers: [
                { text: this.$i18n.t('b96'), value: "text" },
                //{ text: this.$i18n.t('b97'), value: "read_at" },
                { text: "", value: "actions", sortable: false },
            ],
            total: 0,
            itemsPerPage: 10,
            footerProps: {
                'items-per-page-options': [10],  // Cambia las opciones de paginación aquí
                'show-current-page': true,
                'show-first-last-page': true,
                'items-length': 10,
            },
        }
    },
    beforeMount() {
        setTimeout(() => {
            this.loadMessages(0, true);
        }, 1000); //para que no se ejecute cuando se carga la pagina
        setInterval(this.loadMessages, 20 * 60 * 1000);
    },
    methods: {
        read(item) {
            return item.read_at !== null
        },
        allRead() {
            this.notificationService.allRead()
                .then(() => {
                    this.loadMessages(0, false);
                })
        },
        confirmed(item) {
            this.confirm_dialog = false
            this.notificationService.toggleRead(item.id)
            item.read_at = !item.read_at ? DateHelper.today() : null;
            const index = this.notifications.findIndex(notif => notif.id === item.id);
            if (index !== -1) {
                this.notifications[index] = item;
                this.unread();
            }
        },
        unread() {
            let unread = this.notifications.filter(notif => !notif.read_at)
            this.$emit('unread', unread.length);
        },
        searchField() {
            if (this.search && this.search.length > 2) {
                this.loadMessages(0, false, this.search);
            }

            if (!this.search || this.search.length <= 2) {
                this.loadMessages(0, false);
            }

        },
        loadMessages(page = 0, first_time, text = "") {
            let order_by = "read_at,ASC";
            if (!first_time) {
                order_by = "id,DESC";
            }

            this.notificationService.index({ order_by: order_by, page: page, limit: this.itemsPerPage, text: text })
                .then((res) => {
                    this.notifications = res.data;
                    let result = res.next ? 11 : res.items
                    this.total = (this.itemsPerPage * page) + result;
                    this.unread();
                })

        },
        handlePageChange(page) {
            this.loadMessages(page, false);
        },
        getTextDate(date) {
            return date ? DateHelper.dayMonthFormatSmall(date) : ''
        }
    }

}
</script>
<style scoped>
.unread {
    background-color: #f5f5f5;
    font-weight: bold;
}
</style>