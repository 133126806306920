import { i18nActions } from '@/locales/i18n'
class Nation {
    #available_languages = ["en", "es", "it", "de", "fr"]
    list() {
        var lang = i18nActions.getLanguage()
        if(this.#available_languages.indexOf(lang) < 0) {
            lang = "en"
        }
        
        let nations = this.nations().sort((a,b) => 
            (a[lang] > b[lang]) ? 1 : ((b[lang] > a[lang]) ? -1 : 0));
        return nations.map((nation) => {
            return {
                iso_2: nation.iso_2, name: nation[lang], calling_code: nation.calling_code
            };
        });
    }
    
    getNationFromIso2(iso_2) {
        return this.nations().find((n) => n.iso_2.toLowerCase() === iso_2.toLowerCase())
    }
     
      nations(){
            return [ //falla algo de array
                /* eslint-disable */
                {'id': '1','iso_3': 'ABW','iso_2': 'AW','en': 'Aruba','es': 'Aruba','it': 'Aruba','de': 'Aruba','fr': 'Aruba','long': 'Aruba','date_format': 'Y-m-d H:i:s','num_code': '533','calling_code': '297','calling_code2': '297','currency_code': 'AWG','rate': null,'tax_rate': '21'},
                {'id': '2','iso_3': 'AFG','iso_2': 'AF','en': 'Afghanistan','es': 'Afganistán','it': 'Afghanistan','de': 'Afghanistan','fr': 'Afghanistan','long': 'Islamic Republic of Afghanistan','date_format': 'Y-m-d H:i:s','num_code': '004','calling_code': '93','calling_code2': '93','currency_code': 'AFN','rate': null,'tax_rate': '21'},
                {'id': '3','iso_3': 'AGO','iso_2': 'AO','en': 'Angola','es': 'Angola','it': 'Angola','de': 'Angola','fr': 'Angola','long': 'Republic of Angola','date_format': 'Y-m-d H:i:s','num_code': '024','calling_code': '244','calling_code2': '244','currency_code': 'AOA','rate': null,'tax_rate': '21'},
                {'id': '4','iso_3': 'AIA','iso_2': 'AI','en': 'Anguilla','es': 'Anguila','it': 'Anguilla','de': 'Anguilla','fr': 'Anguilla','long': 'Anguilla','date_format': 'Y-m-d H:i:s','num_code': '660','calling_code': '1264','calling_code2': '1+264','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '5','iso_3': 'ALA','iso_2': 'AX','en': 'Aland Islands','es': 'Islas Åland','it': 'Isole Aland','de': 'Alandinseln','fr': 'Îles Åland','long': '&Aring;land Islands','date_format': 'Y-m-d H:i:s','num_code': '248','calling_code': '358','calling_code2': '358','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '6','iso_3': 'ALB','iso_2': 'AL','en': 'Albania','es': 'Albania','it': 'Albania','de': 'Albanien','fr': 'Albanie','long': 'Republic of Albania','date_format': 'd/m/Y H:i:s','num_code': '008','calling_code': '355','calling_code2': '355','currency_code': 'ALL','rate': null,'tax_rate': '21'},
                {'id': '7','iso_3': 'AND','iso_2': 'AD','en': 'Andorra','es': 'Andorra','it': 'Andorra','de': 'Andorra','fr': 'Andorre','long': 'Principality of Andorra','date_format': 'd/m/Y H:i:s','num_code': '020','calling_code': '376','calling_code2': '376','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '8','iso_3': 'ARE','iso_2': 'AE','en': 'United Arab Emirates','es': 'Emiratos Árabes Unidos','it': 'Emirati Arabi Uniti','de': 'Vereinigte Arabische Emirate','fr': 'Émirats arabes unis','long': 'United Arab Emirates','date_format': 'd-m-Y H:i:s','num_code': '784','calling_code': '971','calling_code2': '971','currency_code': 'AED','rate': null,'tax_rate': '21'},
                {'id': '9','iso_3': 'ARG','iso_2': 'AR','en': 'Argentina','es': 'Argentina','it': 'Argentina','de': 'Argentinien','fr': 'Argentine','long': 'Argentine Republic','date_format': 'd/m/Y H:i:s','num_code': '032','calling_code': '54','calling_code2': '54','currency_code': 'ARS','rate': null,'tax_rate': '21'},
                {'id': '10','iso_3': 'ARM','iso_2': 'AM','en': 'Armenia','es': 'Armenia','it': 'Armenia','de': 'Armenien','fr': 'Arménie','long': 'Republic of Armenia','date_format': 'd.m.Y H:i:s','num_code': '051','calling_code': '374','calling_code2': '374','currency_code': 'AMD','rate': null,'tax_rate': '21'},
                {'id': '11','iso_3': 'ASM','iso_2': 'AS','en': 'American Samoa','es': 'Samoa Americana','it': 'Samoa Americane','de': 'Amerikanisch-Samoa','fr': 'Samoa américaines','long': 'American Samoa','date_format': 'Y-m-d H:i:s','num_code': '016','calling_code': '1684','calling_code2': '1+684','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '12','iso_3': 'ATA','iso_2': 'AQ','en': 'Antarctica','es': 'Antártida','it': 'Antartide','de': 'Antarktis','fr': 'Antarctique','long': 'Antarctica','date_format': 'Y-m-d H:i:s','num_code': '010','calling_code': '672','calling_code2': '672','currency_code': '','rate': null,'tax_rate': '21'},
                {'id': '13','iso_3': 'ATF','iso_2': 'TF','en': 'French Southern Territories','es': 'Territorios Australes Franceses','it': 'Territori australi francesi','de': 'Französische Süd- und Antarktisgebiete','fr': 'Terres australes françaises','long': 'French Southern Territories','date_format': 'Y-m-d H:i:s','num_code': '260','calling_code': 'NONE','calling_code2': '','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '14','iso_3': 'ATG','iso_2': 'AG','en': 'Antigua and Barbuda','es': 'Antigua y Barbuda','it': 'Antigua e Barbuda','de': 'Antigua und Barbuda','fr': 'Antigua-et-Barbuda','long': 'Antigua and Barbuda','date_format': 'Y-m-d H:i:s','num_code': '028','calling_code': '1268','calling_code2': '1+268','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '15','iso_3': 'AUS','iso_2': 'AU','en': 'Australia','es': 'Australia','it': 'Australia','de': 'Australien','fr': 'Australie','long': 'Commonwealth of Australia','date_format': 'd/m/Y H:i:s','num_code': '036','calling_code': '61','calling_code2': '61','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '16','iso_3': 'AUT','iso_2': 'AT','en': 'Austria','es': 'Austria','it': 'Austria','de': 'Österreich','fr': 'Autriche','long': 'Republic of Austria','date_format': 'd.m.Y H:i:s','num_code': '040','calling_code': '43','calling_code2': '43','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '17','iso_3': 'AZE','iso_2': 'AZ','en': 'Azerbaijan','es': 'Azerbaiyán','it': 'Azerbaigian','de': 'Aserbaidschan','fr': 'Azerbaïdjan','long': 'Republic of Azerbaijan','date_format': 'd.m.Y H:i:s','num_code': '031','calling_code': '994','calling_code2': '994','currency_code': 'AZN','rate': null,'tax_rate': '21'},
                {'id': '18','iso_3': 'BDI','iso_2': 'BI','en': 'Burundi','es': 'Burundi','it': 'Burundi','de': 'Burundi','fr': 'Burundi','long': 'Republic of Burundi','date_format': 'Y-m-d H:i:s','num_code': '108','calling_code': '257','calling_code2': '257','currency_code': 'BIF','rate': null,'tax_rate': '21'},
                {'id': '19','iso_3': 'BEL','iso_2': 'BE','en': 'Belgium','es': 'Bélgica','it': 'Belgio','de': 'Belgien','fr': 'Belgique','long': 'Kingdom of Belgium','date_format': 'd-m-Y H:i:s','num_code': '056','calling_code': '32','calling_code2': '32','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '20','iso_3': 'BEN','iso_2': 'BJ','en': 'Benin','es': 'Benín','it': 'Benin','de': 'Benin','fr': 'Bénin','long': 'Republic of Benin','date_format': 'Y-m-d H:i:s','num_code': '204','calling_code': '229','calling_code2': '229','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '21','iso_3': 'BES','iso_2': 'BQ','en': 'Bonaire, Sint Eustatius and Saba','es': 'Bonaire, Sint Eustatius and Saba','it': 'Bonaire, Sint Eustatius e Saba','de': '','fr': '','long': 'Bonaire, Sint Eustatius and Saba','date_format': 'Y-m-d H:i:s','num_code': '535','calling_code': '599','calling_code2': '599','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '22','iso_3': 'BFA','iso_2': 'BF','en': 'Burkina Faso','es': 'Burkina Faso','it': 'Burkina Faso','de': 'Burkina Faso','fr': 'Burkina Faso','long': 'Burkina Faso','date_format': 'Y-m-d H:i:s','num_code': '854','calling_code': '226','calling_code2': '226','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '23','iso_3': 'BGD','iso_2': 'BD','en': 'Bangladesh','es': 'Bangladesh','it': 'Bangladesh','de': 'Bangladesch','fr': 'Bangladesh','long': 'People\'s Republic of Bangladesh','date_format': 'Y-m-d H:i:s','num_code': '050','calling_code': '880','calling_code2': '880','currency_code': 'BDT','rate': null,'tax_rate': '21'},
                {'id': '24','iso_3': 'BGR','iso_2': 'BG','en': 'Bulgaria','es': 'Bulgaria','it': 'Bulgaria','de': 'Bulgarien','fr': 'Bulgarie','long': 'Republic of Bulgaria','date_format': 'd.m.Y H:i:s','num_code': '100','calling_code': '359','calling_code2': '359','currency_code': 'BGN','rate': '1.96','tax_rate': '21'},
                {'id': '25','iso_3': 'BHR','iso_2': 'BH','en': 'Bahrain','es': 'Bahréin','it': 'Bahrein','de': 'Bahrain','fr': 'Bahreïn','long': 'Kingdom of Bahrain','date_format': 'Y-m-d H:i:s','num_code': '048','calling_code': '973','calling_code2': '973','currency_code': 'BHD','rate': null,'tax_rate': '21'},
                {'id': '26','iso_3': 'BHS','iso_2': 'BS','en': 'Bahamas','es': 'Bahamas','it': 'Bahamas','de': 'Bahamas','fr': 'Bahamas','long': 'Commonwealth of The Bahamas','date_format': 'Y-m-d H:i:s','num_code': '044','calling_code': '1242','calling_code2': '1+242','currency_code': 'BSD','rate': null,'tax_rate': '21'},
                {'id': '27','iso_3': 'BIH','iso_2': 'BA','en': 'Bosnia and Herzegovina','es': 'Bosnia-Herzegovina','it': 'Bosnia Erzegovina','de': 'Bosnien und Herzegowina','fr': 'Bosnie-Herzégovine','long': 'Bosnia and Herzegovina','date_format': 'd-m-Y H:i:s','num_code': '070','calling_code': '387','calling_code2': '387','currency_code': 'BAM','rate': null,'tax_rate': '21'},
                {'id': '28','iso_3': 'BLM','iso_2': 'BL','en': 'Saint Barthelemy','es': 'San Bartolomé','it': 'San Bartolomeo','de': 'St. Barthélemy','fr': 'Saint-Barthélémy','long': 'Saint Barth&eacute;lemy','date_format': 'Y-m-d H:i:s','num_code': '652','calling_code': '590','calling_code2': '590','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '29','iso_3': 'BLR','iso_2': 'BY','en': 'Belarus','es': 'Bielorrusia','it': 'Bielorussia','de': 'Belarus','fr': 'Bélarus','long': 'Republic of Belarus','date_format': 'd.m.Y H:i:s','num_code': '112','calling_code': '375','calling_code2': '375','currency_code': 'BYR','rate': null,'tax_rate': '21'},
                {'id': '30','iso_3': 'BLZ','iso_2': 'BZ','en': 'Belize','es': 'Belice','it': 'Belize','de': 'Belize','fr': 'Belize','long': 'Belize','date_format': 'd-m-Y H:i:s','num_code': '084','calling_code': '501','calling_code2': '501','currency_code': 'BZD','rate': null,'tax_rate': '21'},
                {'id': '31','iso_3': 'BMU','iso_2': 'BM','en': 'Bermuda','es': 'Bermudas','it': 'Bermuda','de': 'Bermuda','fr': 'Bermudes','long': 'Bermuda Islands','date_format': 'Y-m-d H:i:s','num_code': '060','calling_code': '1441','calling_code2': '1+441','currency_code': 'BMD','rate': null,'tax_rate': '21'},
                {'id': '32','iso_3': 'BOL','iso_2': 'BO','en': 'Bolivia','es': 'Bolivia','it': 'Bolivia','de': 'Bolivien','fr': 'Bolivie','long': 'Plurinational State of Bolivia','date_format': 'd-m-Y H:i:s','num_code': '068','calling_code': '591','calling_code2': '591','currency_code': 'BOB','rate': null,'tax_rate': '21'},
                {'id': '33','iso_3': 'BRA','iso_2': 'BR','en': 'Brazil','es': 'Brasil','it': 'Brasile','de': 'Brasilien','fr': 'Brésil','long': 'Federative Republic of Brazil','date_format': 'd/m/Y H:i:s','num_code': '076','calling_code': '55','calling_code2': '55','currency_code': 'BRL','rate': '3.65','tax_rate': '21'},
                {'id': '34','iso_3': 'BRB','iso_2': 'BB','en': 'Barbados','es': 'Barbados','it': 'Barbados','de': 'Barbados','fr': 'Barbade','long': 'Barbados','date_format': 'd/m/Y H:i:s','num_code': '052','calling_code': '1246','calling_code2': '1+246','currency_code': 'BBD','rate': null,'tax_rate': '21'},
                {'id': '35','iso_3': 'BRN','iso_2': 'BN','en': 'Brunei','es': 'Brunéi','it': 'Brunei','de': 'Brunei Darussalam','fr': 'Brunéi Darussalam','long': 'Brunei Darussalam','date_format': 'd-m-Y H:i:s','num_code': '096','calling_code': '673','calling_code2': '673','currency_code': 'BND','rate': null,'tax_rate': '21'},
                {'id': '36','iso_3': 'BTN','iso_2': 'BT','en': 'Bhutan','es': 'Bután','it': 'Bhutan','de': 'Bhutan','fr': 'Bhoutan','long': 'Kingdom of Bhutan','date_format': 'Y-m-d H:i:s','num_code': '064','calling_code': '975','calling_code2': '975','currency_code': 'BTN','rate': null,'tax_rate': '21'},
                {'id': '37','iso_3': 'BVT','iso_2': 'BV','en': 'Bouvet Island','es': 'Isla Bouvet','it': 'Isola Bouvet','de': 'Bouvetinsel','fr': 'Île Bouvet','long': 'Bouvet Island','date_format': 'Y-m-d H:i:s','num_code': '074','calling_code': 'NONE','calling_code2': 'NONE','currency_code': 'NOK','rate': '9.44','tax_rate': '21'},
                {'id': '38','iso_3': 'BWA','iso_2': 'BW','en': 'Botswana','es': 'Botsuana','it': 'Botswana','de': 'Botsuana','fr': 'Botswana','long': 'Republic of Botswana','date_format': 'Y-m-d H:i:s','num_code': '072','calling_code': '267','calling_code2': '267','currency_code': 'BWP','rate': null,'tax_rate': '21'},
                {'id': '39','iso_3': 'CAF','iso_2': 'CF','en': 'Central African Republic','es': 'República Centroafricana','it': 'Repubblica Centrafricana','de': 'Zentralafrikanische Republik','fr': 'République centrafricaine','long': 'Central African Republic','date_format': 'Y-m-d H:i:s','num_code': '140','calling_code': '236','calling_code2': '236','currency_code': 'XAF','rate': null,'tax_rate': '21'},
                {'id': '40','iso_3': 'CAN','iso_2': 'CA','en': 'Canada','es': 'Canadá','it': 'Canada','de': 'Kanada','fr': 'Canada','long': 'Canada','date_format': 'd/m/Y H:i:s','num_code': '124','calling_code': '1','calling_code2': '1','currency_code': 'CAD','rate': '1.5','tax_rate': '21'},
                {'id': '41','iso_3': 'CCK','iso_2': 'CC','en': 'Cocos (Keeling) Islands','es': 'Islas Cocos','it': 'Isole Cocos','de': 'Kokosinseln','fr': 'Îles Cocos - Keeling','long': 'Cocos (Keeling) Islands','date_format': 'Y-m-d H:i:s','num_code': '166','calling_code': '61','calling_code2': '61','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '42','iso_3': 'CHE','iso_2': 'CH','en': 'Switzerland','es': 'Suiza','it': 'Svizzera','de': 'Schweiz','fr': 'Suisse','long': 'Swiss Confederation','date_format': 'd.m.Y H:i:s','num_code': '756','calling_code': '41','calling_code2': '41','currency_code': 'CHF','rate': '1.09','tax_rate': '21'},
                {'id': '43','iso_3': 'CHL','iso_2': 'CL','en': 'Chile','es': 'Chile','it': 'Cile','de': 'Chile','fr': 'Chili','long': 'Republic of Chile','date_format': 'd-m-Y H:i:s','num_code': '152','calling_code': '56','calling_code2': '56','currency_code': 'CLP','rate': null,'tax_rate': '21'},
                {'id': '44','iso_3': 'CHN','iso_2': 'CN','en': 'China','es': 'China','it': 'Cina','de': 'China','fr': 'Chine','long': 'People\'s Republic of China','date_format': 'Y-m-d H:i:s','num_code': '156','calling_code': '86','calling_code2': '86','currency_code': 'CNY','rate': '7.66','tax_rate': '21'},
                {'id': '45','iso_3': 'CIV','iso_2': 'CI','en': 'Cote d\'ivoire (Ivory Coast)','es': 'Costa de Marfil','it': 'Costa d’Avorio','de': 'Côte d’Ivoire','fr': 'Côte d’Ivoire','long': 'Republic of C&ocirc;te D\'Ivoire (Ivory Coast)','date_format': 'Y-m-d H:i:s','num_code': '384','calling_code': '225','calling_code2': '225','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '46','iso_3': 'CMR','iso_2': 'CM','en': 'Cameroon','es': 'Camerún','it': 'Camerun','de': 'Kamerun','fr': 'Cameroun','long': 'Republic of Cameroon','date_format': 'Y-m-d H:i:s','num_code': '120','calling_code': '237','calling_code2': '237','currency_code': 'XAF','rate': null,'tax_rate': '21'},
                {'id': '47','iso_3': 'COD','iso_2': 'CD','en': 'Democratic Republic of the Congo','es': 'República Democrática del Congo','it': 'Repubblica Democratica del Congo','de': 'Demokratische Republik Kongo','fr': 'République démocratique du Congo','long': 'Democratic Republic of the Congo','date_format': 'Y-m-d H:i:s','num_code': '180','calling_code': '243','calling_code2': '243','currency_code': 'CDF','rate': null,'tax_rate': '21'},
                {'id': '48','iso_3': 'COG','iso_2': 'CG','en': 'Congo','es': 'Congo','it': 'Congo','de': 'Kongo','fr': 'Congo','long': 'Republic of the Congo','date_format': 'Y-m-d H:i:s','num_code': '178','calling_code': '242','calling_code2': '242','currency_code': 'XAF','rate': null,'tax_rate': '21'},
                {'id': '49','iso_3': 'COK','iso_2': 'CK','en': 'Cook Islands','es': 'Islas Cook','it': 'Isole Cook','de': 'Cookinseln','fr': 'Îles Cook','long': 'Cook Islands','date_format': 'Y-m-d H:i:s','num_code': '184','calling_code': '682','calling_code2': '682','currency_code': 'NZD','rate': '1.58','tax_rate': '21'},
                {'id': '50','iso_3': 'COL','iso_2': 'CO','en': 'Colombia','es': 'Colombia','it': 'Colombia','de': 'Kolumbien','fr': 'Colombie','long': 'Republic of Colombia','date_format': 'd-m-Y H:i:s','num_code': '170','calling_code': '57','calling_code2': '57','currency_code': 'COP','rate': null,'tax_rate': '21'},
                {'id': '51','iso_3': 'COM','iso_2': 'KM','en': 'Comoros','es': 'Comoras','it': 'Comore','de': 'Komoren','fr': 'Comores','long': 'Union of the Comoros','date_format': 'Y-m-d H:i:s','num_code': '174','calling_code': '269','calling_code2': '269','currency_code': 'KMF','rate': null,'tax_rate': '21'},
                {'id': '52','iso_3': 'CPV','iso_2': 'CV','en': 'Cape Verde','es': 'Cabo Verde','it': 'Capo Verde','de': 'Kap Verde','fr': 'Cap-Vert','long': 'Republic of Cape Verde','date_format': 'd-m-Y H:i:s','num_code': '132','calling_code': '238','calling_code2': '238','currency_code': 'CVE','rate': null,'tax_rate': '21'},
                {'id': '53','iso_3': 'CRI','iso_2': 'CR','en': 'Costa Rica','es': 'Costa Rica','it': 'Costa Rica','de': 'Costa Rica','fr': 'Costa Rica','long': 'Republic of Costa Rica','date_format': 'd-m-Y H:i:s','num_code': '188','calling_code': '506','calling_code2': '506','currency_code': 'CRC','rate': null,'tax_rate': '21'},
                {'id': '54','iso_3': 'CUB','iso_2': 'CU','en': 'Cuba','es': 'Cuba','it': 'Cuba','de': 'Kuba','fr': 'Cuba','long': 'Republic of Cuba','date_format': 'Y-m-d H:i:s','num_code': '192','calling_code': '53','calling_code2': '53','currency_code': 'CUP','rate': null,'tax_rate': '21'},
                {'id': '55','iso_3': 'CUW','iso_2': 'CW','en': 'Curacao','es': 'País de Curazao','it': 'Curacao','de': '','fr': '','long': 'Cura&ccedil;ao','date_format': 'Y-m-d H:i:s','num_code': '531','calling_code': '599','calling_code2': '599','currency_code': 'ANG','rate': null,'tax_rate': '21'},
                {'id': '56','iso_3': 'CXR','iso_2': 'CX','en': 'Christmas Island','es': 'Isla Christmas','it': 'Isola di Christmas','de': 'Weihnachtsinsel','fr': 'Île Christmas','long': 'Christmas Island','date_format': 'Y-m-d H:i:s','num_code': '162','calling_code': '61','calling_code2': '61','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '57','iso_3': 'CYM','iso_2': 'KY','en': 'Cayman Islands','es': 'Islas Caimán','it': 'Isole Cayman','de': 'Kaimaninseln','fr': 'Îles Caïmans','long': 'The Cayman Islands','date_format': 'Y-m-d H:i:s','num_code': '136','calling_code': '1345','calling_code2': '1+345','currency_code': 'KYD','rate': null,'tax_rate': '21'},
                {'id': '58','iso_3': 'CYP','iso_2': 'CY','en': 'Cyprus','es': 'Chipre','it': 'Cipro','de': 'Zypern','fr': 'Chypre','long': 'Republic of Cyprus','date_format': 'd-m-Y H:i:s','num_code': '196','calling_code': '357','calling_code2': '357','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '59','iso_3': 'CZE','iso_2': 'CZ','en': 'Czech Republic','es': 'República Checa','it': 'Repubblica Ceca','de': 'Tschechische Republik','fr': 'République tchèque','long': 'Czech Republic','date_format': 'd.m.Y H:i:s','num_code': '203','calling_code': '420','calling_code2': '420','currency_code': 'CZK','rate': '26.47','tax_rate': '21'},
                {'id': '60','iso_3': 'DEU','iso_2': 'DE','en': 'Germany','es': 'Alemania','it': 'Germania','de': 'Deutschland','fr': 'Allemagne','long': 'Federal Republic of Germany','date_format': 'Y-m-d H:i:s','num_code': '276','calling_code': '49','calling_code2': '49','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '61','iso_3': 'DJI','iso_2': 'DJ','en': 'Djibouti','es': 'Yibuti','it': 'Gibuti','de': 'Dschibuti','fr': 'Djibouti','long': 'Republic of Djibouti','date_format': 'Y-m-d H:i:s','num_code': '262','calling_code': '253','calling_code2': '253','currency_code': 'DJF','rate': null,'tax_rate': '21'},
                {'id': '62','iso_3': 'DMA','iso_2': 'DM','en': 'Dominica','es': 'Dominica','it': 'Dominica','de': 'Dominica','fr': 'Dominique','long': 'Commonwealth of Dominica','date_format': 'Y-m-d H:i:s','num_code': '212','calling_code': '1767','calling_code2': '1+767','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '63','iso_3': 'DNK','iso_2': 'DK','en': 'Denmark','es': 'Dinamarca','it': 'Danimarca','de': 'Dänemark','fr': 'Danemark','long': 'Kingdom of Denmark','date_format': 'd-m-Y H:i:s','num_code': '208','calling_code': '45','calling_code2': '45','currency_code': 'DKK','rate': '7.44','tax_rate': '21'},
                {'id': '64','iso_3': 'DOM','iso_2': 'DO','en': 'Dominican Republic','es': 'República Dominicana','it': 'Repubblica Dominicana','de': 'Dominikanische Republik','fr': 'République dominicaine','long': 'Dominican Republic','date_format': 'd-m-Y H:i:s','num_code': '214','calling_code': '1809','calling_code2': '1+809, 8','currency_code': 'DOP','rate': null,'tax_rate': '21'},
                {'id': '65','iso_3': 'DZA','iso_2': 'DZ','en': 'Algeria','es': 'Argelia','it': 'Algeria','de': 'Algerien','fr': 'Algérie','long': 'People\'s Democratic Republic of Algeria','date_format': 'd/m/Y H:i:s','num_code': '012','calling_code': '213','calling_code2': '213','currency_code': 'DZD','rate': null,'tax_rate': '21'},
                {'id': '66','iso_3': 'ECU','iso_2': 'EC','en': 'Ecuador','es': 'Ecuador','it': 'Ecuador','de': 'Ecuador','fr': 'Équateur','long': 'Republic of Ecuador','date_format': 'd-m-Y H:i:s','num_code': '218','calling_code': '593','calling_code2': '593','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '67','iso_3': 'EGY','iso_2': 'EG','en': 'Egypt','es': 'Egipto','it': 'Egitto','de': 'Ägypten','fr': 'Égypte','long': 'Arab Republic of Egypt','date_format': 'd-m-Y H:i:s','num_code': '818','calling_code': '20','calling_code2': '20','currency_code': 'EGP','rate': null,'tax_rate': '21'},
                {'id': '68','iso_3': 'ERI','iso_2': 'ER','en': 'Eritrea','es': 'Eritrea','it': 'Eritrea','de': 'Eritrea','fr': 'Érythrée','long': 'State of Eritrea','date_format': 'Y-m-d H:i:s','num_code': '232','calling_code': '291','calling_code2': '291','currency_code': 'ERN','rate': null,'tax_rate': '21'},
                {'id': '69','iso_3': 'ESH','iso_2': 'EH','en': 'Western Sahara','es': 'Sáhara Occidental','it': 'Sahara Occidentale','de': 'Westsahara','fr': 'Sahara occidental','long': 'Western Sahara','date_format': 'Y-m-d H:i:s','num_code': '732','calling_code': '212','calling_code2': '212','currency_code': 'MAD','rate': null,'tax_rate': '21'},
                {'id': '70','iso_3': 'ESP','iso_2': 'ES','en': 'Spain','es': 'España','it': 'Spagna','de': 'Spanien','fr': 'Espagne','long': 'Kingdom of Spain','date_format': 'd/m/Y H:i:s','num_code': '724','calling_code': '34','calling_code2': '34','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '71','iso_3': 'EST','iso_2': 'EE','en': 'Estonia','es': 'Estonia','it': 'Estonia','de': 'Estland','fr': 'Estonie','long': 'Republic of Estonia','date_format': 'd-m-Y H:i:s','num_code': '233','calling_code': '372','calling_code2': '372','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '72','iso_3': 'ETH','iso_2': 'ET','en': 'Ethiopia','es': 'Etiopía','it': 'Etiopia','de': 'Äthiopien','fr': 'Éthiopie','long': 'Federal Democratic Republic of Ethiopia','date_format': 'd-m-Y H:i:s','num_code': '231','calling_code': '251','calling_code2': '251','currency_code': 'ETB','rate': null,'tax_rate': '21'},
                {'id': '73','iso_3': 'FIN','iso_2': 'FI','en': 'Finland','es': 'Finlandia','it': 'Finlandia','de': 'Finnland','fr': 'Finlande','long': 'Republic of Finland','date_format': 'd.m.Y H:i:s','num_code': '246','calling_code': '358','calling_code2': '358','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '74','iso_3': 'FJI','iso_2': 'FJ','en': 'Fiji','es': 'Fiyi','it': 'Figi','de': 'Fidschi','fr': 'Fidji','long': 'Republic of Fiji','date_format': 'Y-m-d H:i:s','num_code': '242','calling_code': '679','calling_code2': '679','currency_code': 'FJD','rate': null,'tax_rate': '21'},
                {'id': '75','iso_3': 'FLK','iso_2': 'FK','en': 'Falkland Islands (Malvinas)','es': 'Islas Malvinas','it': 'Isole Falkland','de': 'Falklandinseln','fr': 'Îles Malouines','long': 'The Falkland Islands (Malvinas)','date_format': 'Y-m-d H:i:s','num_code': '238','calling_code': '500','calling_code2': '500','currency_code': 'FKP','rate': null,'tax_rate': '21'},
                {'id': '76','iso_3': 'FRA','iso_2': 'FR','en': 'France','es': 'Francia','it': 'Francia','de': 'Frankreich','fr': 'France','long': 'French Republic','date_format': 'd/m/Y H:i:s','num_code': '250','calling_code': '33','calling_code2': '33','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '77','iso_3': 'FRO','iso_2': 'FO','en': 'Faroe Islands','es': 'Islas Feroe','it': 'Isole Faroe','de': 'Färöer','fr': 'Îles Féroé','long': 'The Faroe Islands','date_format': 'Y-m-d H:i:s','num_code': '234','calling_code': '298','calling_code2': '298','currency_code': 'DKK','rate': '7.44','tax_rate': '21'},
                {'id': '78','iso_3': 'FSM','iso_2': 'FM','en': 'Micronesia','es': 'Micronesia','it': 'Micronesia','de': 'Mikronesien','fr': 'États fédérés de Micronésie','long': 'Federated States of Micronesia','date_format': 'Y-m-d H:i:s','num_code': '583','calling_code': '691','calling_code2': '691','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '79','iso_3': 'GAB','iso_2': 'GA','en': 'Gabon','es': 'Gabón','it': 'Gabon','de': 'Gabun','fr': 'Gabon','long': 'Gabonese Republic','date_format': 'Y-m-d H:i:s','num_code': '266','calling_code': '241','calling_code2': '241','currency_code': 'XAF','rate': null,'tax_rate': '21'},
                {'id': '80','iso_3': 'GBR','iso_2': 'GB','en': 'United Kingdom','es': 'Reino Unido','it': 'Regno Unito','de': 'Vereinigtes Königreich','fr': 'Royaume-Uni','long': 'United Kingdom of Great Britain and Nothern Ireland','date_format': 'd-m-Y H:i:s','num_code': '826','calling_code': '44','calling_code2': '44','currency_code': 'GBP','rate': '0.87','tax_rate': '21'},
                {'id': '81','iso_3': 'GEO','iso_2': 'GE','en': 'Georgia','es': 'Georgia','it': 'Georgia','de': 'Georgien','fr': 'Géorgie','long': 'Georgia','date_format': 'd.m.Y H:i:s','num_code': '268','calling_code': '995','calling_code2': '995','currency_code': 'GEL','rate': null,'tax_rate': '21'},
                {'id': '82','iso_3': 'GGY','iso_2': 'GG','en': 'Guernsey','es': 'Guernsey','it': 'Guernsey','de': 'Guernsey','fr': 'Guernesey','long': 'Guernsey','date_format': 'Y-m-d H:i:s','num_code': '831','calling_code': '44','calling_code2': '44','currency_code': 'GBP','rate': '0.87','tax_rate': '21'},
                {'id': '83','iso_3': 'GHA','iso_2': 'GH','en': 'Ghana','es': 'Ghana','it': 'Ghana','de': 'Ghana','fr': 'Ghana','long': 'Republic of Ghana','date_format': 'Y-m-d H:i:s','num_code': '288','calling_code': '233','calling_code2': '233','currency_code': 'GHS','rate': null,'tax_rate': '21'},
                {'id': '84','iso_3': 'GIB','iso_2': 'GI','en': 'Gibraltar','es': 'Gibraltar','it': 'Gibilterra','de': 'Gibraltar','fr': 'Gibraltar','long': 'Gibraltar','date_format': 'Y-m-d H:i:s','num_code': '292','calling_code': '350','calling_code2': '350','currency_code': 'GIP','rate': null,'tax_rate': '21'},
                {'id': '85','iso_3': 'GIN','iso_2': 'GN','en': 'Guinea','es': 'Guinea','it': 'Guinea','de': 'Guinea','fr': 'Guinée','long': 'Republic of Guinea','date_format': 'Y-m-d H:i:s','num_code': '324','calling_code': '224','calling_code2': '224','currency_code': 'GNF','rate': null,'tax_rate': '21'},
                {'id': '86','iso_3': 'GLP','iso_2': 'GP','en': 'Guadaloupe','es': 'Guadalupe','it': 'Guadalupa','de': 'Guadeloupe','fr': 'Guadeloupe','long': 'Guadeloupe','date_format': 'Y-m-d H:i:s','num_code': '312','calling_code': '590','calling_code2': '590','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '87','iso_3': 'GMB','iso_2': 'GM','en': 'Gambia','es': 'Gambia','it': 'Gambia','de': 'Gambia','fr': 'Gambie','long': 'Republic of The Gambia','date_format': 'Y-m-d H:i:s','num_code': '270','calling_code': '220','calling_code2': '220','currency_code': 'GMD','rate': null,'tax_rate': '21'},
                {'id': '88','iso_3': 'GNB','iso_2': 'GW','en': 'Guinea-Bissau','es': 'Guinea-Bissau','it': 'Guinea-Bissau','de': 'Guinea-Bissau','fr': 'Guinée-Bissau','long': 'Republic of Guinea-Bissau','date_format': 'Y-m-d H:i:s','num_code': '624','calling_code': '245','calling_code2': '245','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '89','iso_3': 'GNQ','iso_2': 'GQ','en': 'Equatorial Guinea','es': 'Guinea Ecuatorial','it': 'Guinea Equatoriale','de': 'Äquatorialguinea','fr': 'Guinée équatoriale','long': 'Republic of Equatorial Guinea','date_format': 'Y-m-d H:i:s','num_code': '226','calling_code': '240','calling_code2': '240','currency_code': 'XAF','rate': null,'tax_rate': '21'},
                {'id': '90','iso_3': 'GRC','iso_2': 'GR','en': 'Greece','es': 'Grecia','it': 'Grecia','de': 'Griechenland','fr': 'Grèce','long': 'Hellenic Republic','date_format': 'd-m-Y H:i:s','num_code': '300','calling_code': '30','calling_code2': '30','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '91','iso_3': 'GRD','iso_2': 'GD','en': 'Grenada','es': 'Granada','it': 'Grenada','de': 'Grenada','fr': 'Grenade','long': 'Grenada','date_format': 'd-m-Y H:i:s','num_code': '308','calling_code': '1473','calling_code2': '1+473','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '92','iso_3': 'GRL','iso_2': 'GL','en': 'Greenland','es': 'Groenlandia','it': 'Groenlandia','de': 'Grönland','fr': 'Groenland','long': 'Greenland','date_format': 'd.m.Y H:i:s','num_code': '304','calling_code': '299','calling_code2': '299','currency_code': 'DKK','rate': '7.44','tax_rate': '21'},
                {'id': '93','iso_3': 'GTM','iso_2': 'GT','en': 'Guatemala','es': 'Guatemala','it': 'Guatemala','de': 'Guatemala','fr': 'Guatemala','long': 'Republic of Guatemala','date_format': 'Y-m-d H:i:s','num_code': '320','calling_code': '502','calling_code2': '502','currency_code': 'GTQ','rate': null,'tax_rate': '21'},
                {'id': '94','iso_3': 'GUF','iso_2': 'GF','en': 'French Guiana','es': 'Guayana Francesa','it': 'Guiana Francese','de': 'Französisch-Guayana','fr': 'Guyane française','long': 'French Guiana','date_format': 'Y-m-d H:i:s','num_code': '254','calling_code': '594','calling_code2': '594','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '95','iso_3': 'GUM','iso_2': 'GU','en': 'Guam','es': 'Guam','it': 'Guam','de': 'Guam','fr': 'Guam','long': 'Guam','date_format': 'Y-m-d H:i:s','num_code': '316','calling_code': '1671','calling_code2': '1+671','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '96','iso_3': 'GUY','iso_2': 'GY','en': 'Guyana','es': 'Guyana','it': 'Guyana','de': 'Guyana','fr': 'Guyana','long': 'Co-operative Republic of Guyana','date_format': 'd-m-Y H:i:s','num_code': '328','calling_code': '592','calling_code2': '592','currency_code': 'GYD','rate': null,'tax_rate': '21'},
                {'id': '97','iso_3': 'HKG','iso_2': 'HK','en': 'Hong Kong','es': 'Hong Kong','it': 'Hong Kong','de': 'Hongkong','fr': 'Hong Kong','long': 'Hong Kong','date_format': 'd/m/Y H:i:s','num_code': '344','calling_code': '852','calling_code2': '852','currency_code': 'HKD','rate': '8.71','tax_rate': '21'},
                {'id': '98','iso_3': 'HMD','iso_2': 'HM','en': 'Heard Island and McDonald Islands','es': 'Islas Heard y McDonald','it': 'Isole Heard ed Isole McDonald','de': 'Heard- und McDonald-Inseln','fr': 'Îles Heard et MacDonald','long': 'Heard Island and McDonald Islands','date_format': 'Y-m-d H:i:s','num_code': '334','calling_code': 'NONE','calling_code2': 'NONE','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '99','iso_3': 'HND','iso_2': 'HN','en': 'Honduras','es': 'Honduras','it': 'Honduras','de': 'Honduras','fr': 'Honduras','long': 'Republic of Honduras','date_format': 'd-m-Y H:i:s','num_code': '340','calling_code': '504','calling_code2': '504','currency_code': 'HNL','rate': null,'tax_rate': '21'},
                {'id': '100','iso_3': 'HRV','iso_2': 'HR','en': 'Croatia','es': 'Croacia','it': 'Croazia','de': 'Kroatien','fr': 'Croatie','long': 'Republic of Croatia','date_format': 'd.m.Y H:i:s','num_code': '191','calling_code': '385','calling_code2': '385','currency_code': 'HRK','rate': '7.42','tax_rate': '21'},
                {'id': '101','iso_3': 'HTI','iso_2': 'HT','en': 'Haiti','es': 'Haití','it': 'Haiti','de': 'Haiti','fr': 'Haïti','long': 'Republic of Haiti','date_format': 'Y-m-d H:i:s','num_code': '332','calling_code': '509','calling_code2': '509','currency_code': 'HTG','rate': null,'tax_rate': '21'},
                {'id': '102','iso_3': 'HUN','iso_2': 'HU','en': 'Hungary','es': 'Hungría','it': 'Ungheria','de': 'Ungarn','fr': 'Hongrie','long': 'Hungary','date_format': 'Y-m-d H:i:s','num_code': '348','calling_code': '36','calling_code2': '36','currency_code': 'HUF','rate': '308.63','tax_rate': '21'},
                {'id': '103','iso_3': 'IDN','iso_2': 'ID','en': 'Indonesia','es': 'Indonesia','it': 'Indonesia','de': 'Indonesien','fr': 'Indonésie','long': 'Republic of Indonesia','date_format': 'd-m-Y H:i:s','num_code': '360','calling_code': '62','calling_code2': '62','currency_code': 'IDR','rate': '999.99','tax_rate': '21'},
                {'id': '104','iso_3': 'IMN','iso_2': 'IM','en': 'Isle of Man','es': 'Isla de Man','it': 'Isola di Man','de': 'Isle of Man','fr': 'Île de Man','long': 'Isle of Man','date_format': 'Y-m-d H:i:s','num_code': '833','calling_code': '44','calling_code2': '44','currency_code': 'GBP','rate': '0.87','tax_rate': '21'},
                {'id': '105','iso_3': 'IND','iso_2': 'IN','en': 'India','es': 'India','it': 'India','de': 'Indien','fr': 'Inde','long': 'Republic of India','date_format': 'd-m-Y H:i:s','num_code': '356','calling_code': '91','calling_code2': '91','currency_code': 'INR','rate': '72.24','tax_rate': '21'},
                {'id': '106','iso_3': 'IOT','iso_2': 'IO','en': 'British Indian Ocean Territory','es': 'Territorio Británico del Océano Índico','it': 'Territorio Britannico dell’Oceano Indiano','de': 'Britisches Territorium im Indischen Ozean','fr': 'Territoire britannique de l\'océan Indien','long': 'British Indian Ocean Territory','date_format': 'Y-m-d H:i:s','num_code': '086','calling_code': '246','calling_code2': '246','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '107','iso_3': 'IRL','iso_2': 'IE','en': 'Ireland','es': 'Irlanda','it': 'Irlanda','de': 'Irland','fr': 'Irlande','long': 'Ireland','date_format': 'd-m-Y H:i:s','num_code': '372','calling_code': '353','calling_code2': '353','currency_code': 'EUR','rate': '1','tax_rate': '23'},
                {'id': '108','iso_3': 'IRN','iso_2': 'IR','en': 'Iran','es': 'Irán','it': 'Iran','de': 'Iran','fr': 'Iran','long': 'Islamic Republic of Iran','date_format': 'Y-m-d H:i:s','num_code': '364','calling_code': '98','calling_code2': '98','currency_code': 'IRR','rate': null,'tax_rate': '21'},
                {'id': '109','iso_3': 'IRQ','iso_2': 'IQ','en': 'Iraq','es': 'Iraq','it': 'Iraq','de': 'Irak','fr': 'Irak','long': 'Republic of Iraq','date_format': 'd/m/Y H:i:s','num_code': '368','calling_code': '964','calling_code2': '964','currency_code': 'IQD','rate': null,'tax_rate': '21'},
                {'id': '110','iso_3': 'ISL','iso_2': 'IS','en': 'Iceland','es': 'Islandia','it': 'Islanda','de': 'Island','fr': 'Islande','long': 'Republic of Iceland','date_format': 'd.m.Y H:i:s','num_code': '352','calling_code': '354','calling_code2': '354','currency_code': 'ISK','rate': null,'tax_rate': '21'},
                {'id': '111','iso_3': 'ISR','iso_2': 'IL','en': 'Israel','es': 'Israel','it': 'Israele','de': 'Israel','fr': 'Israël','long': 'State of Israel','date_format': 'd/m/Y H:i:s','num_code': '376','calling_code': '972','calling_code2': '972','currency_code': 'ILS','rate': '3.97','tax_rate': '21'},
                {'id': '112','iso_3': 'ITA','iso_2': 'IT','en': 'Italy','es': 'Italia','it': 'Italia','de': 'Italien','fr': 'Italie','long': 'Italian Republic','date_format': 'd/m/Y H:i:s','num_code': '380','calling_code': '39','calling_code2': '39','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '113','iso_3': 'JAM','iso_2': 'JM','en': 'Jamaica','es': 'Jamaica','it': 'Giamaica','de': 'Jamaika','fr': 'Jamaïque','long': 'Jamaica','date_format': 'd-m-Y H:i:s','num_code': '388','calling_code': '1876','calling_code2': '1+876','currency_code': 'JMD','rate': null,'tax_rate': '21'},
                {'id': '114','iso_3': 'JEY','iso_2': 'JE','en': 'Jersey','es': 'Jersey','it': 'Jersey','de': 'Jersey','fr': 'Jersey','long': 'The Bailiwick of Jersey','date_format': 'Y-m-d H:i:s','num_code': '832','calling_code': '44','calling_code2': '44','currency_code': 'GBP','rate': '0.87','tax_rate': '21'},
                {'id': '115','iso_3': 'JOR','iso_2': 'JO','en': 'Jordan','es': 'Jordania','it': 'Giordania','de': 'Jordanien','fr': 'Jordanie','long': 'Hashemite Kingdom of Jordan','date_format': 'd-m-Y H:i:s','num_code': '400','calling_code': '962','calling_code2': '962','currency_code': 'JOD','rate': null,'tax_rate': '21'},
                {'id': '116','iso_3': 'JPN','iso_2': 'JP','en': 'Japan','es': 'Japón','it': 'Giappone','de': 'Japan','fr': 'Japon','long': 'Japan','date_format': 'Y-m-d H:i:s','num_code': '392','calling_code': '81','calling_code2': '81','currency_code': 'JPY','rate': '123.97','tax_rate': '21'},
                {'id': '117','iso_3': 'KAZ','iso_2': 'KZ','en': 'Kazakhstan','es': 'Kazajistán','it': 'Kazakistan','de': 'Kasachstan','fr': 'Kazakhstan','long': 'Republic of Kazakhstan','date_format': 'Y.m.d H:i:s','num_code': '398','calling_code': '7','calling_code2': '7','currency_code': 'KZT','rate': null,'tax_rate': '21'},
                {'id': '118','iso_3': 'KEN','iso_2': 'KE','en': 'Kenya','es': 'Kenia','it': 'Kenya','de': 'Kenia','fr': 'Kenya','long': 'Republic of Kenya','date_format': 'Y-m-d H:i:s','num_code': '404','calling_code': '254','calling_code2': '254','currency_code': 'KES','rate': null,'tax_rate': '21'},
                {'id': '119','iso_3': 'KGZ','iso_2': 'KG','en': 'Kyrgyzstan','es': 'Kirguistán','it': 'Kirghizistan','de': 'Kirgisistan','fr': 'Kirghizistan','long': 'Kyrgyz Republic','date_format': 'd.m.Y H:i:s','num_code': '417','calling_code': '996','calling_code2': '996','currency_code': 'KGS','rate': null,'tax_rate': '21'},
                {'id': '120','iso_3': 'KHM','iso_2': 'KH','en': 'Cambodia','es': 'Camboya','it': 'Cambogia','de': 'Kambodscha','fr': 'Cambodge','long': 'Kingdom of Cambodia','date_format': 'Y-m-d H:i:s','num_code': '116','calling_code': '855','calling_code2': '855','currency_code': 'KHR','rate': null,'tax_rate': '21'},
                {'id': '121','iso_3': 'KIR','iso_2': 'KI','en': 'Kiribati','es': 'Kiribati','it': 'Kiribati','de': 'Kiribati','fr': 'Kiribati','long': 'Republic of Kiribati','date_format': 'Y-m-d H:i:s','num_code': '296','calling_code': '686','calling_code2': '686','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '122','iso_3': 'KNA','iso_2': 'KN','en': 'Saint Kitts and Nevis','es': 'San Cristóbal y Nieves','it': 'Saint Kitts e Nevis','de': 'St. Kitts und Nevis','fr': 'Saint-Kitts-et-Nevis','long': 'Federation of Saint Christopher and Nevis','date_format': 'd-m-Y H:i:s','num_code': '659','calling_code': '1869','calling_code2': '1+869','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '123','iso_3': 'KOR','iso_2': 'KR','en': 'South Korea','es': 'Corea del Sur','it': 'Corea del Sud','de': 'Republik Korea','fr': 'Corée du Sud','long': 'Republic of Korea','date_format': 'Y-m-d H:i:s','num_code': '410','calling_code': '82','calling_code2': '82','currency_code': 'KRW','rate': '999.99','tax_rate': '21'},
                {'id': '124','iso_3': 'KWT','iso_2': 'KW','en': 'Kuwait','es': 'Kuwait','it': 'Kuwait','de': 'Kuwait','fr': 'Koweït','long': 'State of Kuwait','date_format': 'd-m-Y H:i:s','num_code': '414','calling_code': '965','calling_code2': '965','currency_code': 'KWD','rate': null,'tax_rate': '21'},
                {'id': '125','iso_3': 'LAO','iso_2': 'LA','en': 'Laos','es': 'Laos','it': 'Laos','de': 'Laos','fr': 'Laos','long': 'Lao People\'s Democratic Republic','date_format': 'd-m-Y H:i:s','num_code': '418','calling_code': '856','calling_code2': '856','currency_code': 'LAK','rate': null,'tax_rate': '21'},
                {'id': '126','iso_3': 'LBN','iso_2': 'LB','en': 'Lebanon','es': 'Líbano','it': 'Libano','de': 'Libanon','fr': 'Liban','long': 'Republic of Lebanon','date_format': 'd-m-Y H:i:s','num_code': '422','calling_code': '961','calling_code2': '961','currency_code': 'LBP','rate': null,'tax_rate': '21'},
                {'id': '127','iso_3': 'LBR','iso_2': 'LR','en': 'Liberia','es': 'Liberia','it': 'Liberia','de': 'Liberia','fr': 'Libéria','long': 'Republic of Liberia','date_format': 'Y-m-d H:i:s','num_code': '430','calling_code': '231','calling_code2': '231','currency_code': 'LRD','rate': null,'tax_rate': '21'},
                {'id': '128','iso_3': 'LBY','iso_2': 'LY','en': 'Libya','es': 'Libia','it': 'Libia','de': 'Libyen','fr': 'Libye','long': 'Libya','date_format': 'd-m-Y H:i:s','num_code': '434','calling_code': '218','calling_code2': '218','currency_code': 'LYD','rate': null,'tax_rate': '21'},
                {'id': '129','iso_3': 'LCA','iso_2': 'LC','en': 'Saint Lucia','es': 'Santa Lucía','it': 'Saint Lucia','de': 'St. Lucia','fr': 'Sainte-Lucie','long': 'Saint Lucia','date_format': 'd-m-Y H:i:s','num_code': '662','calling_code': '1758','calling_code2': '1+758','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '130','iso_3': 'LIE','iso_2': 'LI','en': 'Liechtenstein','es': 'Liechtenstein','it': 'Liechtenstein','de': 'Liechtenstein','fr': 'Liechtenstein','long': 'Principality of Liechtenstein','date_format': 'd.m.Y H:i:s','num_code': '438','calling_code': '423','calling_code2': '423','currency_code': 'CHF','rate': '1.09','tax_rate': '21'},
                {'id': '131','iso_3': 'LKA','iso_2': 'LK','en': 'Sri Lanka','es': 'Sri Lanka','it': 'Sri Lanka','de': 'Sri Lanka','fr': 'Sri Lanka','long': 'Democratic Socialist Republic of Sri Lanka','date_format': 'Y-m-d H:i:s','num_code': '144','calling_code': '94','calling_code2': '94','currency_code': 'LKR','rate': null,'tax_rate': '21'},
                {'id': '132','iso_3': 'LSO','iso_2': 'LS','en': 'Lesotho','es': 'Lesoto','it': 'Lesotho','de': 'Lesotho','fr': 'Lesotho','long': 'Kingdom of Lesotho','date_format': 'Y-m-d H:i:s','num_code': '426','calling_code': '266','calling_code2': '266','currency_code': 'LSL','rate': null,'tax_rate': '21'},
                {'id': '133','iso_3': 'LTU','iso_2': 'LT','en': 'Lithuania','es': 'Lituania','it': 'Lituania','de': 'Litauen','fr': 'Lituanie','long': 'Republic of Lithuania','date_format': 'Y-m-d H:i:s','num_code': '440','calling_code': '370','calling_code2': '370','currency_code': 'LTL','rate': null,'tax_rate': '21'},
                {'id': '134','iso_3': 'LUX','iso_2': 'LU','en': 'Luxembourg','es': 'Luxemburgo','it': 'Lussemburgo','de': 'Luxemburg','fr': 'Luxembourg','long': 'Grand Duchy of Luxembourg','date_format': 'd-m-Y H:i:s','num_code': '442','calling_code': '352','calling_code2': '352','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '135','iso_3': 'LVA','iso_2': 'LV','en': 'Latvia','es': 'Letonia','it': 'Lettonia','de': 'Lettland','fr': 'Lettonie','long': 'Republic of Latvia','date_format': 'd.m.Y H:i:s','num_code': '428','calling_code': '371','calling_code2': '371','currency_code': 'LVL','rate': null,'tax_rate': '21'},
                {'id': '136','iso_3': 'MAC','iso_2': 'MO','en': 'Macao','es': 'Macao','it': 'Macao','de': 'Macao','fr': 'Macao','long': 'The Macao Special Administrative Region','date_format': 'd/m/Y H:i:s','num_code': '446','calling_code': '853','calling_code2': '853','currency_code': 'MOP','rate': null,'tax_rate': '21'},
                {'id': '137','iso_3': 'MAF','iso_2': 'MF','en': 'Saint Martin','es': 'San Martín','it': 'Sint Martin','de': 'St. Martin','fr': 'Saint-Martin','long': 'Saint Martin','date_format': 'Y-m-d H:i:s','num_code': '663','calling_code': '590','calling_code2': '590','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '138','iso_3': 'MAR','iso_2': 'MA','en': 'Morocco','es': 'Marruecos','it': 'Marocco','de': 'Marokko','fr': 'Maroc','long': 'Kingdom of Morocco','date_format': 'd-m-Y H:i:s','num_code': '504','calling_code': '212','calling_code2': '212','currency_code': 'MAD','rate': null,'tax_rate': '21'},
                {'id': '139','iso_3': 'MCO','iso_2': 'MC','en': 'Monaco','es': 'Mónaco','it': 'Monaco','de': 'Monaco','fr': 'Monaco','long': 'Principality of Monaco','date_format': 'd-m-Y H:i:s','num_code': '492','calling_code': '377','calling_code2': '377','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '140','iso_3': 'MDA','iso_2': 'MD','en': 'Moldava','es': 'Moldavia','it': 'Moldavia','de': 'Republik Moldau','fr': 'Moldavie','long': 'Republic of Moldova','date_format': 'd-m-Y H:i:s','num_code': '498','calling_code': '373','calling_code2': '373','currency_code': 'MDL','rate': null,'tax_rate': '21'},
                {'id': '141','iso_3': 'MDG','iso_2': 'MG','en': 'Madagascar','es': 'Madagascar','it': 'Madagascar','de': 'Madagaskar','fr': 'Madagascar','long': 'Republic of Madagascar','date_format': 'Y-m-d H:i:s','num_code': '450','calling_code': '261','calling_code2': '261','currency_code': 'MGA','rate': null,'tax_rate': '21'},
                {'id': '142','iso_3': 'MDV','iso_2': 'MV','en': 'Maldives','es': 'Maldivas','it': 'Maldive','de': 'Malediven','fr': 'Maldives','long': 'Republic of Maldives','date_format': 'd-m-Y H:i:s','num_code': '462','calling_code': '960','calling_code2': '960','currency_code': 'MVR','rate': null,'tax_rate': '21'},
                {'id': '143','iso_3': 'MEX','iso_2': 'MX','en': 'Mexico','es': 'México','it': 'Messico','de': 'Mexiko','fr': 'Mexique','long': 'United Mexican States','date_format': 'd-m-Y H:i:s','num_code': '484','calling_code': '52','calling_code2': '52','currency_code': 'MXN','rate': '20.73','tax_rate': '21'},
                {'id': '144','iso_3': 'MHL','iso_2': 'MH','en': 'Marshall Islands','es': 'Islas Marshall','it': 'Isole Marshall','de': 'Marshallinseln','fr': 'Îles Marshall','long': 'Republic of the Marshall Islands','date_format': 'Y-m-d H:i:s','num_code': '584','calling_code': '692','calling_code2': '692','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '145','iso_3': 'MKD','iso_2': 'MK','en': 'Macedonia','es': 'Macedonia','it': 'Repubblica di Macedonia','de': 'Mazedonien','fr': 'Macédoine','long': 'The Former Yugoslav Republic of Macedonia','date_format': 'd.m.Y H:i:s','num_code': '807','calling_code': '389','calling_code2': '389','currency_code': 'MKD','rate': null,'tax_rate': '21'},
                {'id': '146','iso_3': 'MLI','iso_2': 'ML','en': 'Mali','es': 'Mali','it': 'Mali','de': 'Mali','fr': 'Mali','long': 'Republic of Mali','date_format': 'Y-m-d H:i:s','num_code': '466','calling_code': '223','calling_code2': '223','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '147','iso_3': 'MLT','iso_2': 'MT','en': 'Malta','es': 'Malta','it': 'Malta','de': 'Malta','fr': 'Malte','long': 'Republic of Malta','date_format': 'd-m-Y H:i:s','num_code': '470','calling_code': '356','calling_code2': '356','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '148','iso_3': 'MMR','iso_2': 'MM','en': 'Myanmar (Burma)','es': 'Myanmar','it': 'Myanmar','de': 'Myanmar','fr': 'Myanmar','long': 'Republic of the Union of Myanmar','date_format': 'Y-m-d H:i:s','num_code': '104','calling_code': '95','calling_code2': '95','currency_code': 'MMK','rate': null,'tax_rate': '21'},
                {'id': '149','iso_3': 'MNE','iso_2': 'ME','en': 'Montenegro','es': 'Montenegro','it': 'Montenegro','de': 'Montenegro','fr': 'Monténégro','long': 'Montenegro','date_format': 'd.m.Y H:i:s','num_code': '499','calling_code': '382','calling_code2': '382','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '150','iso_3': 'MNG','iso_2': 'MN','en': 'Mongolia','es': 'Mongolia','it': 'Mongolia','de': 'Mongolei','fr': 'Mongolie','long': 'Mongolia','date_format': 'Y.m.d H:i:s','num_code': '496','calling_code': '976','calling_code2': '976','currency_code': 'MNT','rate': null,'tax_rate': '21'},
                {'id': '151','iso_3': 'MNP','iso_2': 'MP','en': 'Northern Mariana Islands','es': 'Islas Marianas del Norte','it': 'Isole Marianne Settentrionali','de': 'Nördliche Marianen','fr': 'Îles Mariannes du Nord','long': 'Northern Mariana Islands','date_format': 'Y-m-d H:i:s','num_code': '580','calling_code': '1670','calling_code2': '1+670','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '152','iso_3': 'MOZ','iso_2': 'MZ','en': 'Mozambique','es': 'Mozambique','it': 'Mozambico','de': 'Mosambik','fr': 'Mozambique','long': 'Republic of Mozambique','date_format': 'Y-m-d H:i:s','num_code': '508','calling_code': '258','calling_code2': '258','currency_code': 'MZN','rate': null,'tax_rate': '21'},
                {'id': '153','iso_3': 'MRT','iso_2': 'MR','en': 'Mauritania','es': 'Mauritania','it': 'Mauritania','de': 'Mauretanien','fr': 'Mauritanie','long': 'Islamic Republic of Mauritania','date_format': 'Y-m-d H:i:s','num_code': '478','calling_code': '222','calling_code2': '222','currency_code': 'MRO','rate': null,'tax_rate': '21'},
                {'id': '154','iso_3': 'MSR','iso_2': 'MS','en': 'Montserrat','es': 'Montserrat','it': 'Montserrat','de': 'Montserrat','fr': 'Montserrat','long': 'Montserrat','date_format': 'Y-m-d H:i:s','num_code': '500','calling_code': '1664','calling_code2': '1+664','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '155','iso_3': 'MTQ','iso_2': 'MQ','en': 'Martinique','es': 'Martinica','it': 'Martinica','de': 'Martinique','fr': 'Martinique','long': 'Martinique','date_format': 'Y-m-d H:i:s','num_code': '474','calling_code': '596','calling_code2': '596','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '156','iso_3': 'MUS','iso_2': 'MU','en': 'Mauritius','es': 'Mauricio','it': 'Mauritius','de': 'Mauritius','fr': 'Maurice','long': 'Republic of Mauritius','date_format': 'Y-m-d H:i:s','num_code': '480','calling_code': '230','calling_code2': '230','currency_code': 'MUR','rate': null,'tax_rate': '21'},
                {'id': '157','iso_3': 'MWI','iso_2': 'MW','en': 'Malawi','es': 'Malaui','it': 'Malawi','de': 'Malawi','fr': 'Malawi','long': 'Republic of Malawi','date_format': 'Y-m-d H:i:s','num_code': '454','calling_code': '265','calling_code2': '265','currency_code': 'MWK','rate': null,'tax_rate': '21'},
                {'id': '158','iso_3': 'MYS','iso_2': 'MY','en': 'Malaysia','es': 'Malasia','it': 'Malesia','de': 'Malaysia','fr': 'Malaisie','long': 'Malaysia','date_format': 'Y-m-d H:i:s','num_code': '458','calling_code': '60','calling_code2': '60','currency_code': 'MYR','rate': '4.78','tax_rate': '21'},
                {'id': '159','iso_3': 'MYT','iso_2': 'YT','en': 'Mayotte','es': 'Mayotte','it': 'Mayotte','de': 'Mayotte','fr': 'Mayotte','long': 'Mayotte','date_format': 'Y-m-d H:i:s','num_code': '175','calling_code': '262','calling_code2': '262','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '160','iso_3': 'NAM','iso_2': 'NA','en': 'Namibia','es': 'Namibia','it': 'Namibia','de': 'Namibia','fr': 'Namibie','long': 'Republic of Namibia','date_format': 'Y-m-d H:i:s','num_code': '516','calling_code': '264','calling_code2': '264','currency_code': 'NAD','rate': null,'tax_rate': '21'},
                {'id': '161','iso_3': 'NCL','iso_2': 'NC','en': 'New Caledonia','es': 'Nueva Caledonia','it': 'Nuova Caledonia','de': 'Neukaledonien','fr': 'Nouvelle-Calédonie','long': 'New Caledonia','date_format': 'Y-m-d H:i:s','num_code': '540','calling_code': '687','calling_code2': '687','currency_code': 'XPF','rate': null,'tax_rate': '21'},
                {'id': '162','iso_3': 'NER','iso_2': 'NE','en': 'Niger','es': 'Níger','it': 'Niger','de': 'Niger','fr': 'Niger','long': 'Republic of Niger','date_format': 'Y-m-d H:i:s','num_code': '562','calling_code': '227','calling_code2': '227','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '163','iso_3': 'NFK','iso_2': 'NF','en': 'Norfolk Island','es': 'Isla Norfolk','it': 'Isola Norfolk','de': 'Norfolkinsel','fr': 'Île Norfolk','long': 'Norfolk Island','date_format': 'Y-m-d H:i:s','num_code': '574','calling_code': '672','calling_code2': '672','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '164','iso_3': 'NGA','iso_2': 'NG','en': 'Nigeria','es': 'Nigeria','it': 'Nigeria','de': 'Nigeria','fr': 'Nigéria','long': 'Federal Republic of Nigeria','date_format': 'd/m/Y H:i:s','num_code': '566','calling_code': '234','calling_code2': '234','currency_code': 'NGN','rate': null,'tax_rate': '21'},
                {'id': '165','iso_3': 'NIC','iso_2': 'NI','en': 'Nicaragua','es': 'Nicaragua','it': 'Nicaragua','de': 'Nicaragua','fr': 'Nicaragua','long': 'Republic of Nicaragua','date_format': 'd-m-Y H:i:s','num_code': '558','calling_code': '505','calling_code2': '505','currency_code': 'NIO','rate': null,'tax_rate': '21'},
                {'id': '166','iso_3': 'NIU','iso_2': 'NU','en': 'Niue','es': 'Isla Niue','it': 'Niue','de': 'Niue','fr': 'Niue','long': 'Niue','date_format': 'Y-m-d H:i:s','num_code': '570','calling_code': '683','calling_code2': '683','currency_code': 'NZD','rate': '1.58','tax_rate': '21'},
                {'id': '167','iso_3': 'NLD','iso_2': 'NL','en': 'Netherlands','es': 'Países Bajos','it': 'Paesi Bassi','de': 'Niederlande','fr': 'Pays-Bas','long': 'Kingdom of the Netherlands','date_format': 'd-m-Y H:i:s','num_code': '528','calling_code': '31','calling_code2': '31','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '168','iso_3': 'NOR','iso_2': 'NO','en': 'Norway','es': 'Noruega','it': 'Norvegia','de': 'Norwegen','fr': 'Norvège','long': 'Kingdom of Norway','date_format': 'd.m.Y H:i:s','num_code': '578','calling_code': '47','calling_code2': '47','currency_code': 'NOK','rate': '9.44','tax_rate': '21'},
                {'id': '169','iso_3': 'NPL','iso_2': 'NP','en': 'Nepal','es': 'Nepal','it': 'Nepal','de': 'Nepal','fr': 'Népal','long': 'Federal Democratic Republic of Nepal','date_format': 'Y-m-d H:i:s','num_code': '524','calling_code': '977','calling_code2': '977','currency_code': 'NPR','rate': null,'tax_rate': '21'},
                {'id': '170','iso_3': 'NRU','iso_2': 'NR','en': 'Nauru','es': 'Nauru','it': 'Nauru','de': 'Nauru','fr': 'Nauru','long': 'Republic of Nauru','date_format': 'Y-m-d H:i:s','num_code': '520','calling_code': '674','calling_code2': '674','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '171','iso_3': 'NZL','iso_2': 'NZ','en': 'New Zealand','es': 'Nueva Zelanda','it': 'Nuova Zelanda','de': 'Neuseeland','fr': 'Nouvelle-Zélande','long': 'New Zealand','date_format': 'd-m-Y H:i:s','num_code': '554','calling_code': '64','calling_code2': '64','currency_code': 'NZD','rate': '1.58','tax_rate': '21'},
                {'id': '172','iso_3': 'OMN','iso_2': 'OM','en': 'Oman','es': 'Omán','it': 'Oman','de': 'Oman','fr': 'Oman','long': 'Sultanate of Oman','date_format': 'd-m-Y H:i:s','num_code': '512','calling_code': '968','calling_code2': '968','currency_code': 'OMR','rate': null,'tax_rate': '21'},
                {'id': '173','iso_3': 'PAK','iso_2': 'PK','en': 'Pakistan','es': 'Pakistán','it': 'Pakistan','de': 'Pakistan','fr': 'Pakistan','long': 'Islamic Republic of Pakistan','date_format': 'd/m/Y H:i:s','num_code': '586','calling_code': '92','calling_code2': '92','currency_code': 'PKR','rate': null,'tax_rate': '21'},
                {'id': '174','iso_3': 'PAN','iso_2': 'PA','en': 'Panama','es': 'Panamá','it': 'Panama','de': 'Panama','fr': 'Panama','long': 'Republic of Panama','date_format': 'd-m-Y H:i:s','num_code': '591','calling_code': '507','calling_code2': '507','currency_code': 'PAB','rate': null,'tax_rate': '21'},
                {'id': '175','iso_3': 'PCN','iso_2': 'PN','en': 'Pitcairn','es': 'Pitcairn','it': 'Pitcairn','de': 'Pitcairn','fr': 'Pitcairn','long': 'Pitcairn','date_format': 'Y-m-d H:i:s','num_code': '612','calling_code': 'NONE','calling_code2': 'NONE','currency_code': 'NZD','rate': '1.58','tax_rate': '21'},
                {'id': '176','iso_3': 'PER','iso_2': 'PE','en': 'Peru','es': 'Perú','it': 'Perù','de': 'Peru','fr': 'Pérou','long': 'Republic of Peru','date_format': 'd-m-Y H:i:s','num_code': '604','calling_code': '51','calling_code2': '51','currency_code': 'PEN','rate': null,'tax_rate': '21'},
                {'id': '177','iso_3': 'PHL','iso_2': 'PH','en': 'Phillipines','es': 'Filipinas','it': 'Filippine','de': 'Philippinen','fr': 'Philippines','long': 'Republic of the Philippines','date_format': 'd-m-Y H:i:s','num_code': '608','calling_code': '63','calling_code2': '63','currency_code': 'PHP','rate': '55.63','tax_rate': '21'},
                {'id': '178','iso_3': 'PLW','iso_2': 'PW','en': 'Palau','es': 'Palau','it': 'Palau','de': 'Palau','fr': 'Palaos','long': 'Republic of Palau','date_format': 'd-m-Y H:i:s','num_code': '585','calling_code': '680','calling_code2': '680','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '179','iso_3': 'PNG','iso_2': 'PG','en': 'Papua New Guinea','es': 'Papúa Nueva Guinea','it': 'Papua Nuova Guinea','de': 'Papua-Neuguinea','fr': 'Papouasie-Nouvelle-Guinée','long': 'Independent State of Papua New Guinea','date_format': 'Y-m-d H:i:s','num_code': '598','calling_code': '675','calling_code2': '675','currency_code': 'PGK','rate': null,'tax_rate': '21'},
                {'id': '180','iso_3': 'POL','iso_2': 'PL','en': 'Poland','es': 'Polonia','it': 'Polonia','de': 'Polen','fr': 'Pologne','long': 'Republic of Poland','date_format': 'd-m-Y H:i:s','num_code': '616','calling_code': '48','calling_code2': '48','currency_code': 'PLN','rate': '4.18','tax_rate': '21'},
                {'id': '181','iso_3': 'PRI','iso_2': 'PR','en': 'Puerto Rico','es': 'Puerto Rico','it': 'Portorico','de': 'Puerto Rico','fr': 'Porto Rico','long': 'Commonwealth of Puerto Rico','date_format': 'Y-m-d H:i:s','num_code': '630','calling_code': '1939','calling_code2': '1+939','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '182','iso_3': 'PRK','iso_2': 'KP','en': 'North Korea','es': 'Corea del Norte','it': 'Corea del Nord','de': 'Demokratische Volksrepublik Korea','fr': 'Corée du Nord','long': 'Democratic People\'s Republic of Korea','date_format': 'Y-m-d H:i:s','num_code': '408','calling_code': '850','calling_code2': '850','currency_code': 'KPW','rate': null,'tax_rate': '21'},
                {'id': '183','iso_3': 'PRT','iso_2': 'PT','en': 'Portugal','es': 'Portugal','it': 'Portogallo','de': 'Portugal','fr': 'Portugal','long': 'Portuguese Republic','date_format': 'd/m/Y H:i:s','num_code': '620','calling_code': '351','calling_code2': '351','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '184','iso_3': 'PRY','iso_2': 'PY','en': 'Paraguay','es': 'Paraguay','it': 'Paraguay','de': 'Paraguay','fr': 'Paraguay','long': 'Republic of Paraguay','date_format': 'd-m-Y H:i:s','num_code': '600','calling_code': '595','calling_code2': '595','currency_code': 'PYG','rate': null,'tax_rate': '21'},
                {'id': '185','iso_3': 'PSE','iso_2': 'PS','en': 'Palestine','es': 'Palestina','it': 'Palestina','de': 'Palästinensische Gebiete','fr': 'Territoire palestinien','long': 'State of Palestine (or Occupied Palestinian Territory)','date_format': 'Y-m-d H:i:s','num_code': '275','calling_code': '970','calling_code2': '970','currency_code': 'ILS','rate': '3.97','tax_rate': '21'},
                {'id': '186','iso_3': 'PYF','iso_2': 'PF','en': 'French Polynesia','es': 'Polinesia Francesa','it': 'Polinesia Francese','de': 'Französisch-Polynesien','fr': 'Polynésie française','long': 'French Polynesia','date_format': 'Y-m-d H:i:s','num_code': '258','calling_code': '689','calling_code2': '689','currency_code': 'XPF','rate': null,'tax_rate': '21'},
                {'id': '187','iso_3': 'QAT','iso_2': 'QA','en': 'Qatar','es': 'Qatar','it': 'Qatar','de': 'Katar','fr': 'Qatar','long': 'State of Qatar','date_format': 'd-m-Y H:i:s','num_code': '634','calling_code': '974','calling_code2': '974','currency_code': 'QAR','rate': null,'tax_rate': '21'},
                {'id': '188','iso_3': 'REU','iso_2': 'RE','en': 'Reunion','es': 'Reunión','it': 'Réunion','de': 'Réunion','fr': 'Réunion','long': 'R&eacute;union','date_format': 'Y-m-d H:i:s','num_code': '638','calling_code': '262','calling_code2': '262','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '189','iso_3': 'ROU','iso_2': 'RO','en': 'Romania','es': 'Rumanía','it': 'Romania','de': 'Rumänien','fr': 'Roumanie','long': 'Romania','date_format': 'd.m.Y H:i:s','num_code': '642','calling_code': '40','calling_code2': '40','currency_code': 'RON','rate': '4.57','tax_rate': '21'},
                {'id': '190','iso_3': 'RUS','iso_2': 'RU','en': 'Russia','es': 'Rusia','it': 'Federazione Russa','de': 'Russische Föderation','fr': 'Russie','long': 'Russian Federation','date_format': 'd.m.Y H:i:s','num_code': '643','calling_code': '7','calling_code2': '7','currency_code': 'RUB','rate': '63.26','tax_rate': '21'},
                {'id': '191','iso_3': 'RWA','iso_2': 'RW','en': 'Rwanda','es': 'Ruanda','it': 'Ruanda','de': 'Ruanda','fr': 'Rwanda','long': 'Republic of Rwanda','date_format': 'Y-m-d H:i:s','num_code': '646','calling_code': '250','calling_code2': '250','currency_code': 'RWF','rate': null,'tax_rate': '21'},
                {'id': '192','iso_3': 'SAU','iso_2': 'SA','en': 'Saudi Arabia','es': 'Arabia Saudí','it': 'Arabia Saudita','de': 'Saudi-Arabien','fr': 'Arabie saoudite','long': 'Kingdom of Saudi Arabia','date_format': 'd-m-Y H:i:s','num_code': '682','calling_code': '966','calling_code2': '966','currency_code': 'SAR','rate': null,'tax_rate': '21'},
                {'id': '193','iso_3': 'SDN','iso_2': 'SD','en': 'Sudan','es': 'Sudán','it': 'Sudan','de': 'Sudan','fr': 'Soudan','long': 'Republic of the Sudan','date_format': 'd-m-Y H:i:s','num_code': '729','calling_code': '249','calling_code2': '249','currency_code': 'SDG','rate': null,'tax_rate': '21'},
                {'id': '194','iso_3': 'SEN','iso_2': 'SN','en': 'Senegal','es': 'Senegal','it': 'Senegal','de': 'Senegal','fr': 'Sénégal','long': 'Republic of Senegal','date_format': 'Y-m-d H:i:s','num_code': '686','calling_code': '221','calling_code2': '221','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '195','iso_3': 'SGP','iso_2': 'SG','en': 'Singapore','es': 'Singapur','it': 'Singapore','de': 'Singapur','fr': 'Singapour','long': 'Republic of Singapore','date_format': 'd-m-Y H:i:s','num_code': '702','calling_code': '65','calling_code2': '65','currency_code': 'SGD','rate': '1.55','tax_rate': '21'},
                {'id': '196','iso_3': 'SGS','iso_2': 'GS','en': 'South Georgia and the South Sandwich Islands','es': 'Islas Georgia del Sur y Sandwich del Sur','it': 'Georgia del Sud e Isole Sandwich del Sud','de': 'Südgeorgien und die Südlichen Sandwichinseln','fr': 'Géorgie du Sud et les îles Sandwich du Sud','long': 'South Georgia and the South Sandwich Islands','date_format': 'Y-m-d H:i:s','num_code': '239','calling_code': '500','calling_code2': '500','currency_code': 'GBP','rate': '0.87','tax_rate': '21'},
                {'id': '197','iso_3': 'SHN','iso_2': 'SH','en': 'Saint Helena','es': 'Santa Elena','it': 'Sant’Elena','de': 'St. Helena','fr': 'Sainte-Hélène','long': 'Saint Helena, Ascension and Tristan da Cunha','date_format': 'Y-m-d H:i:s','num_code': '654','calling_code': '290','calling_code2': '290','currency_code': 'SHP','rate': null,'tax_rate': '21'},
                {'id': '198','iso_3': 'SJM','iso_2': 'SJ','en': 'Svalbard and Jan Mayen','es': 'Svalbard y Jan Mayen','it': 'Svalbard e Jan Mayen','de': 'Svalbard und Jan Mayen','fr': 'Svalbard et Île Jan Mayen','long': 'Svalbard and Jan Mayen','date_format': 'Y-m-d H:i:s','num_code': '744','calling_code': '47','calling_code2': '47','currency_code': 'NOK','rate': '9.44','tax_rate': '21'},
                {'id': '199','iso_3': 'SLB','iso_2': 'SB','en': 'Solomon Islands','es': 'Islas Salomón','it': 'Isole Solomon','de': 'Salomonen','fr': 'Îles Salomon','long': 'Solomon Islands','date_format': 'Y-m-d H:i:s','num_code': '090','calling_code': '677','calling_code2': '677','currency_code': 'SBD','rate': null,'tax_rate': '21'},
                {'id': '200','iso_3': 'SLE','iso_2': 'SL','en': 'Sierra Leone','es': 'Sierra Leona','it': 'Sierra Leone','de': 'Sierra Leone','fr': 'Sierra Leone','long': 'Republic of Sierra Leone','date_format': 'Y-m-d H:i:s','num_code': '694','calling_code': '232','calling_code2': '232','currency_code': 'SLL','rate': null,'tax_rate': '21'},
                {'id': '201','iso_3': 'SLV','iso_2': 'SV','en': 'El Salvador','es': 'El Salvador','it': 'El Salvador','de': 'El Salvador','fr': 'El Salvador','long': 'Republic of El Salvador','date_format': 'd-m-Y H:i:s','num_code': '222','calling_code': '503','calling_code2': '503','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '202','iso_3': 'SMR','iso_2': 'SM','en': 'San Marino','es': 'San Marino','it': 'San Marino','de': 'San Marino','fr': 'Saint-Marin','long': 'Republic of San Marino','date_format': 'Y-m-d H:i:s','num_code': '674','calling_code': '378','calling_code2': '378','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '203','iso_3': 'SOM','iso_2': 'SO','en': 'Somalia','es': 'Somalia','it': 'Somalia','de': 'Somalia','fr': 'Somalie','long': 'Somali Republic','date_format': 'Y-m-d H:i:s','num_code': '706','calling_code': '252','calling_code2': '252','currency_code': 'SOS','rate': null,'tax_rate': '21'},
                {'id': '204','iso_3': 'SPM','iso_2': 'PM','en': 'Saint Pierre and Miquelon','es': 'San Pedro y Miquelón','it': 'Saint Pierre e Miquelon','de': 'St. Pierre und Miquelon','fr': 'Saint-Pierre-et-Miquelon','long': 'Saint Pierre and Miquelon','date_format': 'Y-m-d H:i:s','num_code': '666','calling_code': '508','calling_code2': '508','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '205','iso_3': 'SRB','iso_2': 'RS','en': 'Serbia','es': 'Serbia','it': 'Serbia','de': 'Serbien','fr': 'Serbie','long': 'Republic of Serbia','date_format': 'd.m.Y H:i:s','num_code': '688','calling_code': '381','calling_code2': '381','currency_code': 'RSD','rate': null,'tax_rate': '21'},
                {'id': '206','iso_3': 'SSD','iso_2': 'SS','en': 'South Sudan','es': 'República de Sudán del Sur','it': 'Sudan del Sud','de': '','fr': '','long': 'Republic of South Sudan','date_format': 'Y-m-d H:i:s','num_code': '728','calling_code': '211','calling_code2': '211','currency_code': 'SSP','rate': null,'tax_rate': '21'},
                {'id': '207','iso_3': 'STP','iso_2': 'ST','en': 'Sao Tome and Principe','es': 'Santo Tomé y Príncipe','it': 'Sao Tomé e Príncipe','de': 'São Tomé und Príncipe','fr': 'Sao Tomé-et-Principe','long': 'Democratic Republic of S&atilde;o Tom&eacute; and Pr&iacute;ncipe','date_format': 'Y-m-d H:i:s','num_code': '678','calling_code': '239','calling_code2': '239','currency_code': 'STD','rate': null,'tax_rate': '21'},
                {'id': '208','iso_3': 'SUR','iso_2': 'SR','en': 'Suriname','es': 'Surinam','it': 'Suriname','de': 'Suriname','fr': 'Suriname','long': 'Republic of Suriname','date_format': 'd-m-Y H:i:s','num_code': '740','calling_code': '597','calling_code2': '597','currency_code': 'SRD','rate': null,'tax_rate': '21'},
                {'id': '209','iso_3': 'SVK','iso_2': 'SK','en': 'Slovakia','es': 'Eslovaquia','it': 'Slovacchia','de': 'Slowakei','fr': 'Slovaquie','long': 'Slovak Republic','date_format': 'd.m.Y H:i:s','num_code': '703','calling_code': '421','calling_code2': '421','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '210','iso_3': 'SVN','iso_2': 'SI','en': 'Slovenia','es': 'Eslovenia','it': 'Slovenia','de': 'Slowenien','fr': 'Slovénie','long': 'Republic of Slovenia','date_format': 'd.m.Y H:i:s','num_code': '705','calling_code': '386','calling_code2': '386','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '211','iso_3': 'SWE','iso_2': 'SE','en': 'Sweden','es': 'Suecia','it': 'Svezia','de': 'Schweden','fr': 'Suède','long': 'Kingdom of Sweden','date_format': 'Y-m-d H:i:s','num_code': '752','calling_code': '46','calling_code2': '46','currency_code': 'SEK','rate': '9.77','tax_rate': '21'},
                {'id': '212','iso_3': 'SWZ','iso_2': 'SZ','en': 'Swaziland','es': 'Suazilandia','it': 'Swaziland','de': 'Swasiland','fr': 'Swaziland','long': 'Kingdom of Swaziland','date_format': 'Y-m-d H:i:s','num_code': '748','calling_code': '268','calling_code2': '268','currency_code': 'SZL','rate': null,'tax_rate': '21'},
                {'id': '213','iso_3': 'SXM','iso_2': 'SX','en': 'Sint Maarten','es': 'Sint Maarten','it': 'Sint Maarten','de': '','fr': '','long': 'Sint Maarten','date_format': 'Y-m-d H:i:s','num_code': '534','calling_code': '1721','calling_code2': '1+721','currency_code': 'ANG','rate': null,'tax_rate': '21'},
                {'id': '214','iso_3': 'SYC','iso_2': 'SC','en': 'Seychelles','es': 'Seychelles','it': 'Seychelles','de': 'Seychellen','fr': 'Seychelles','long': 'Republic of Seychelles','date_format': 'Y-m-d H:i:s','num_code': '690','calling_code': '248','calling_code2': '248','currency_code': 'SCR','rate': null,'tax_rate': '21'},
                {'id': '215','iso_3': 'SYR','iso_2': 'SY','en': 'Syria','es': 'Siria','it': 'Siria','de': 'Syrien','fr': 'Syrie','long': 'Syrian Arab Republic','date_format': 'd-m-Y H:i:s','num_code': '760','calling_code': '963','calling_code2': '963','currency_code': 'SYP','rate': null,'tax_rate': '21'},
                {'id': '216','iso_3': 'TCA','iso_2': 'TC','en': 'Turks and Caicos Islands','es': 'Islas Turcas y Caicos','it': 'Isole Turks e Caicos','de': 'Turks- und Caicosinseln','fr': 'Îles Turks et Caïques','long': 'Turks and Caicos Islands','date_format': 'Y-m-d H:i:s','num_code': '796','calling_code': '1649','calling_code2': '1+649','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '217','iso_3': 'TCD','iso_2': 'TD','en': 'Chad','es': 'Chad','it': 'Ciad','de': 'Tschad','fr': 'Tchad','long': 'Republic of Chad','date_format': 'Y-m-d H:i:s','num_code': '148','calling_code': '235','calling_code2': '235','currency_code': 'XAF','rate': null,'tax_rate': '21'},
                {'id': '218','iso_3': 'TGO','iso_2': 'TG','en': 'Togo','es': 'Togo','it': 'Togo','de': 'Togo','fr': 'Togo','long': 'Togolese Republic','date_format': 'Y-m-d H:i:s','num_code': '768','calling_code': '228','calling_code2': '228','currency_code': 'XOF','rate': null,'tax_rate': '21'},
                {'id': '219','iso_3': 'THA','iso_2': 'TH','en': 'Thailand','es': 'Tailandia','it': 'Tailandia','de': 'Thailand','fr': 'Thaïlande','long': 'Kingdom of Thailand','date_format': 'd-m-Y H:i:s','num_code': '764','calling_code': '66','calling_code2': '66','currency_code': 'THB','rate': '38.13','tax_rate': '21'},
                {'id': '220','iso_3': 'TJK','iso_2': 'TJ','en': 'Tajikistan','es': 'Tayikistán','it': 'Tagikistan','de': 'Tadschikistan','fr': 'Tadjikistan','long': 'Republic of Tajikistan','date_format': 'd.m.Y H:i:s','num_code': '762','calling_code': '992','calling_code2': '992','currency_code': 'TJS','rate': null,'tax_rate': '21'},
                {'id': '221','iso_3': 'TKL','iso_2': 'TK','en': 'Tokelau','es': 'Tokelau','it': 'Tokelau','de': 'Tokelau','fr': 'Tokelau','long': 'Tokelau','date_format': 'Y-m-d H:i:s','num_code': '772','calling_code': '690','calling_code2': '690','currency_code': 'NZD','rate': '1.58','tax_rate': '21'},
                {'id': '222','iso_3': 'TKM','iso_2': 'TM','en': 'Turkmenistan','es': 'Turkmenistán','it': 'Turkmenistan','de': 'Turkmenistan','fr': 'Turkménistan','long': 'Turkmenistan','date_format': 'd.m.Y H:i:s','num_code': '795','calling_code': '993','calling_code2': '993','currency_code': 'TMT','rate': null,'tax_rate': '21'},
                {'id': '223','iso_3': 'TLS','iso_2': 'TL','en': 'Timor-Leste (East Timor)','es': 'Timor Oriental','it': 'Timor Est','de': 'Osttimor','fr': 'Timor oriental','long': 'Democratic Republic of Timor-Leste','date_format': 'Y-m-d H:i:s','num_code': '626','calling_code': '670','calling_code2': '670','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '224','iso_3': 'TON','iso_2': 'TO','en': 'Tonga','es': 'Tonga','it': 'Tonga','de': 'Tonga','fr': 'Tonga','long': 'Kingdom of Tonga','date_format': 'Y-m-d H:i:s','num_code': '776','calling_code': '676','calling_code2': '676','currency_code': 'TOP','rate': null,'tax_rate': '21'},
                {'id': '225','iso_3': 'TTO','iso_2': 'TT','en': 'Trinidad and Tobago','es': 'Trinidad y Tobago','it': 'Trinidad e Tobago','de': 'Trinidad und Tobago','fr': 'Trinité-et-Tobago','long': 'Republic of Trinidad and Tobago','date_format': 'd-m-Y H:i:s','num_code': '780','calling_code': '1868','calling_code2': '1+868','currency_code': 'TTD','rate': null,'tax_rate': '21'},
                {'id': '226','iso_3': 'TUN','iso_2': 'TN','en': 'Tunisia','es': 'Túnez','it': 'Tunisia','de': 'Tunesien','fr': 'Tunisie','long': 'Republic of Tunisia','date_format': 'd-m-Y H:i:s','num_code': '788','calling_code': '216','calling_code2': '216','currency_code': 'TND','rate': null,'tax_rate': '21'},
                {'id': '227','iso_3': 'TUR','iso_2': 'TR','en': 'Turkey','es': 'Turquía','it': 'Turchia','de': 'Türkei','fr': 'Turquie','long': 'Republic of Turkey','date_format': 'd.m.Y H:i:s','num_code': '792','calling_code': '90','calling_code2': '90','currency_code': 'TRY','rate': '3.97','tax_rate': '21'},
                {'id': '228','iso_3': 'TUV','iso_2': 'TV','en': 'Tuvalu','es': 'Tuvalu','it': 'Tuvalu','de': 'Tuvalu','fr': 'Tuvalu','long': 'Tuvalu','date_format': 'Y-m-d H:i:s','num_code': '798','calling_code': '688','calling_code2': '688','currency_code': 'AUD','rate': '1.5','tax_rate': '21'},
                {'id': '229','iso_3': 'TWN','iso_2': 'TW','en': 'Taiwan','es': 'Taiwán','it': 'Taiwan','de': 'Taiwan','fr': 'Taïwan','long': 'Republic of China (Taiwan)','date_format': 'Y-m-d H:i:s','num_code': '158','calling_code': '886','calling_code2': '886','currency_code': 'TWD','rate': null,'tax_rate': '21'},
                {'id': '230','iso_3': 'TZA','iso_2': 'TZ','en': 'Tanzania','es': 'Tanzania','it': 'Tanzania','de': 'Tansania','fr': 'Tanzanie','long': 'United Republic of Tanzania','date_format': 'Y-m-d H:i:s','num_code': '834','calling_code': '255','calling_code2': '255','currency_code': 'TZS','rate': null,'tax_rate': '21'},
                {'id': '231','iso_3': 'UGA','iso_2': 'UG','en': 'Uganda','es': 'Uganda','it': 'Uganda','de': 'Uganda','fr': 'Ouganda','long': 'Republic of Uganda','date_format': 'Y-m-d H:i:s','num_code': '800','calling_code': '256','calling_code2': '256','currency_code': 'UGX','rate': null,'tax_rate': '21'},
                {'id': '232','iso_3': 'UKR','iso_2': 'UA','en': 'Ukraine','es': 'Ucrania','it': 'Ucraina','de': 'Ukraine','fr': 'Ukraine','long': 'Ukraine','date_format': 'd.m.Y H:i:s','num_code': '804','calling_code': '380','calling_code2': '380','currency_code': 'UAH','rate': null,'tax_rate': '21'},
                {'id': '233','iso_3': 'UMI','iso_2': 'UM','en': 'United States Minor Outlying Islands','es': 'Islas menores alejadas de los Estados Unidos','it': 'Isole Minori lontane dagli Stati Uniti','de': 'Amerikanisch-Ozeanien','fr': 'Îles Mineures Éloignées des États-Unis','long': 'United States Minor Outlying Islands','date_format': 'Y-m-d H:i:s','num_code': '581','calling_code': 'NONE','calling_code2': 'NONE','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '234','iso_3': 'URY','iso_2': 'UY','en': 'Uruguay','es': 'Uruguay','it': 'Uruguay','de': 'Uruguay','fr': 'Uruguay','long': 'Eastern Republic of Uruguay','date_format': 'd-m-Y H:i:s','num_code': '858','calling_code': '598','calling_code2': '598','currency_code': 'UYU','rate': null,'tax_rate': '21'},
                {'id': '235','iso_3': 'USA','iso_2': 'US','en': 'United States','es': 'Estados Unidos','it': 'Stati Uniti','de': 'Vereinigte Staaten','fr': 'États-Unis','long': 'United States of America','date_format': 'Y-m-d H:i:s','num_code': '840','calling_code': '1','calling_code2': '1','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '236','iso_3': 'UZB','iso_2': 'UZ','en': 'Uzbekistan','es': 'Uzbekistán','it': 'Uzbekistan','de': 'Usbekistan','fr': 'Ouzbékistan','long': 'Republic of Uzbekistan','date_format': 'd.m.Y H:i:s','num_code': '860','calling_code': '998','calling_code2': '998','currency_code': 'UZS','rate': null,'tax_rate': '21'},
                {'id': '237','iso_3': 'VAT','iso_2': 'VA','en': 'Vatican City','es': 'Ciudad del Vaticano','it': 'Vaticano','de': 'Vatikanstadt','fr': 'État de la Cité du Vatican','long': 'State of the Vatican City','date_format': 'Y-m-d H:i:s','num_code': '336','calling_code': '39','calling_code2': '39','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '238','iso_3': 'VCT','iso_2': 'VC','en': 'Saint Vincent and the Grenadines','es': 'San Vicente y las Granadinas','it': 'Saint Vincent e Grenadines','de': 'St. Vincent und die Grenadinen','fr': 'Saint-Vincent-et-les Grenadines','long': 'Saint Vincent and the Grenadines','date_format': 'd-m-Y H:i:s','num_code': '670','calling_code': '1784','calling_code2': '1+784','currency_code': 'XCD','rate': null,'tax_rate': '21'},
                {'id': '239','iso_3': 'VEN','iso_2': 'VE','en': 'Venezuela','es': 'Venezuela','it': 'Venezuela','de': 'Venezuela','fr': 'Venezuela','long': 'Bolivarian Republic of Venezuela','date_format': 'd-m-Y H:i:s','num_code': '862','calling_code': '58','calling_code2': '58','currency_code': 'VEF','rate': null,'tax_rate': '21'},
                {'id': '240','iso_3': 'VGB','iso_2': 'VG','en': 'Virgin Islands, British','es': 'Islas Vírgenes Británicas','it': 'Isole Vergini Britanniche','de': 'Britische Jungferninseln','fr': 'Îles Vierges britanniques','long': 'British Virgin Islands','date_format': 'Y-m-d H:i:s','num_code': '092','calling_code': '1284','calling_code2': '1+284','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '241','iso_3': 'VIR','iso_2': 'VI','en': 'Virgin Islands, US','es': 'Islas Vírgenes de los Estados Unidos','it': 'Isole Vergini Americane','de': 'Amerikanische Jungferninseln','fr': 'Îles Vierges des États-Unis','long': 'Virgin Islands of the United States','date_format': 'Y-m-d H:i:s','num_code': '850','calling_code': '1340','calling_code2': '1+340','currency_code': 'USD','rate': '1.12','tax_rate': '21'},
                {'id': '242','iso_3': 'VNM','iso_2': 'VN','en': 'Vietnam','es': 'Vietnam','it': 'Vietnam','de': 'Vietnam','fr': 'Viêt Nam','long': 'Socialist Republic of Vietnam','date_format': 'd-m-Y H:i:s','num_code': '704','calling_code': '84','calling_code2': '84','currency_code': 'VND','rate': null,'tax_rate': '21'},
                {'id': '243','iso_3': 'VUT','iso_2': 'VU','en': 'Vanuatu','es': 'Vanuatu','it': 'Vanuatu','de': 'Vanuatu','fr': 'Vanuatu','long': 'Republic of Vanuatu','date_format': 'Y-m-d H:i:s','num_code': '548','calling_code': '678','calling_code2': '678','currency_code': 'VUV','rate': null,'tax_rate': '21'},
                {'id': '244','iso_3': 'WLF','iso_2': 'WF','en': 'Wallis and Futuna','es': 'Wallis y Futuna','it': 'Wallis e Futuna','de': 'Wallis und Futuna','fr': 'Wallis-et-Futuna','long': 'Wallis and Futuna','date_format': 'Y-m-d H:i:s','num_code': '876','calling_code': '681','calling_code2': '681','currency_code': 'XPF','rate': null,'tax_rate': '21'},
                {'id': '245','iso_3': 'WSM','iso_2': 'WS','en': 'Samoa','es': 'Samoa','it': 'Samoa','de': 'Samoa','fr': 'Samoa','long': 'Independent State of Samoa','date_format': 'Y-m-d H:i:s','num_code': '882','calling_code': '685','calling_code2': '685','currency_code': 'WST','rate': null,'tax_rate': '21'},
                {'id': '246','iso_3': 'XKX','iso_2': 'XK','en': 'Kosovo','es': 'Kosovo','it': 'Kosovo','de': '','fr': '','long': 'Republic of Kosovo','date_format': 'Y-m-d H:i:s','num_code': '---','calling_code': '381','calling_code2': '381','currency_code': 'EUR','rate': '1','tax_rate': '21'},
                {'id': '247','iso_3': 'YEM','iso_2': 'YE','en': 'Yemen','es': 'Yemen','it': 'Yemen','de': 'Jemen','fr': 'Yémen','long': 'Republic of Yemen','date_format': 'd-m-Y H:i:s','num_code': '887','calling_code': '967','calling_code2': '967','currency_code': 'YER','rate': null,'tax_rate': '21'},
                {'id': '248','iso_3': 'ZAF','iso_2': 'ZA','en': 'South Africa','es': 'Sudáfrica','it': 'Sudafrica','de': 'Südafrika','fr': 'Afrique du Sud','long': 'Republic of South Africa','date_format': 'Y-m-d H:i:s','num_code': '710','calling_code': '27','calling_code2': '27','currency_code': 'ZAR','rate': '14.69','tax_rate': '21'},
                {'id': '249','iso_3': 'ZMB','iso_2': 'ZM','en': 'Zambia','es': 'Zambia','it': 'Zambia','de': 'Sambia','fr': 'Zambie','long': 'Republic of Zambia','date_format': 'Y-m-d H:i:s','num_code': '894','calling_code': '260','calling_code2': '260','currency_code': 'ZMK','rate': null,'tax_rate': '21'},
                {'id': '250','iso_3': 'ZWE','iso_2': 'ZW','en': 'Zimbabwe','es': 'Zimbabue','it': 'Zimbabwe','de': 'Simbabwe','fr': 'Zimbabwe','long': 'Republic of Zimbabwe','date_format': 'Y-m-d H:i:s','num_code': '716','calling_code': '263','calling_code2': '263','currency_code': 'ZWL','rate': null,'tax_rate': '21'}
            ];
      }/* eslint-enable */
};

export { Nation }