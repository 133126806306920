<template>
  <div :class="selectedClass">
    <v-select v-bind="this.$textFieldProps" v-model="selected_nation" :items="nations" item-text="name"
      item-value="iso_2" :label="label" @change="selectedNation" :error-messages="error_message">
      <template v-if="show_flags" v-slot:selection="{ item }">
        <span class="pr-1 pb-1">
          <country-flag :country="item.iso_2" />
        </span> +{{ item.calling_code }}
      </template>
      <template v-slot:item="{ item }">
        <country-flag :country="item.iso_2" />
        <span class="pt-2 pl-2">{{ item.name }}</span>
      </template>
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field autofocus color="primary" v-model="searchTerm" :placeholder="$t('Search')"
              @input="searchNations" clearable outlined dense prepend-inner-icon="mdi-magnify"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </div>
</template>
<script>
import { Nation } from "@/services/Nation";
import CountryFlag from 'vue-country-flag'
export default {
  name: "searchNations",
  components: {
    CountryFlag,
  },
  props: {
    hide_details: {
      type: Boolean,
      default: true,
    },
    error_message: {
      type: String,
      default: "",
    },
    nation_default: {
      type: String,
      default: "",
    },
    show_flags: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: function () {
        return this.$t("Prefix")
      }
    }
  },
  data() {
    return {
      searchTerm: "",
      nationsCopy: [],
      nations: [],
      selected_nation: "",
    }
  },
  watch: {
    nation_default() {
      this.selected_nation = this.nation_default ? this.nation_default.toUpperCase() : ""
    },
  },
  computed: {
    selectedClass() {
      return this.show_flags ? "flag_width" : ""
    },
  },
  methods: {
    selectedNation() {
      this.$emit("selected_nation", this.selected_nation);
    },
    setNation(nation) {
      this.selected_nation = nation;
    },
    searchNations() {
      if (!this.searchTerm) {
        this.nations = this.nationsCopy;
      }

      this.nations = this.nationsCopy.filter((item) => {
        if (
          item.name &&
          item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        ) {
          return true;
        }

        return false;
      });
    },
  },
  beforeMount() {
    this.nations = new Nation().list();
  },
  mounted() {
    this.nationsCopy = [...this.nations];
    this.selected_nation = this.nation_default.toUpperCase()
  },
}
</script>
<style scoped>
.flag_width {
  width: 130px;
}
</style>
