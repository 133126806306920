import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"pa-5",attrs:{"max-width":"1000","min-width":"800","min-height":"400","max-height":"1000"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('b95')))]),_c(VCardActions,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,_vm._b({staticClass:"mr-6",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"clearable":""},on:{"input":_vm.searchField},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',this.$textFieldProps,false))],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.allRead()}}},[_vm._v(_vm._s(_vm.$t('b227')))])],1)],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.notifications,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:page":_vm.handlePageChange},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{staticClass:"my-5",class:{ 'unread': item.read_at === null }},[_c('td',[_vm._v(_vm._s(item.text))]),_c('td',[_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","color":item.read_at ? 'primary' : 'orange'},on:{"click":function($event){return _vm.confirmed(item)}},model:{value:(item.read_at),callback:function ($$v) {_vm.$set(item, "read_at", $$v)},expression:"item.read_at"}},[_vm._v(_vm._s(item.read_at ? _vm.$t('b97') : _vm.$t('b98')))]),_vm._v(" "+_vm._s(_vm.getTextDate(item.read_at))+" ")],1)])]}}])})],1)],1),_c('ButtonDialog',{attrs:{"dialog":_vm.confirm_dialog,"title":_vm.$t('a78'),"confirmation":""},on:{"cancel":function($event){_vm.confirm_dialog = false},"confirm":_vm.confirmed}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }