<template>
    <v-tooltip :eager="eager" v-bind="tooltipProps" transition="v-expand-transition"
        :content-class="`${tooltip_class} tooltip_card no_transparent`" :open-on-focus="false" :open-on-hover="false"
        v-model="show" bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :class="button_class" icon @click="show = !show" v-bind="attrs" v-on="on">
                <v-badge v-if="has_badge" :color="badge_color" :content="content" overlap>
                    <v-icon :color="icon_color">
                        {{ icon }}
                    </v-icon>
                </v-badge>
                <v-icon v-else :color="icon_color">
                    {{ icon }}
                </v-icon>
            </v-btn>
        </template>
        <div class="ma-5">
            <slot></slot>
        </div>
    </v-tooltip>
</template>
<script>
import Helper from "@/services/Helper";
export default {
    name: "WindowTooltip",
    props: {
        icon: {
            type: String,
        },
        icon_color: {
            type: String,
            default: "black"
        },
        tooltipProps: {
            type: Object, // Acepta un objeto con las propiedades del tooltip
            default: () => ({}), // Valor por defecto: un objeto vacío
        },
        has_badge: {
            type: Boolean,
            default: false
        },
        badge_color: {
            type: String,
            default: "red"
        },
        content: {
            type: String | Number,
            default: ""
        },
        eager: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: false,
            button_class: "",
            tooltip_class: "",
        };
    },
    beforeMount() {
        this.button_class = Helper.randomString(30)
        this.tooltip_class = Helper.randomString(30)
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
            if (this.show) {
                const tooltipContent = document.querySelector('.' + this.tooltip_class); // Selecciona el contenido del tooltip
                const activator = document.querySelector('.' + this.button_class); // Selecciona el botón activador

                if (tooltipContent && !tooltipContent.contains(event.target) && !activator.contains(event.target)) {
                    this.show = false;
                }
            }
        },
    },
}
</script>