import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('ChangeProfile'),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ChangeEmail'),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ChangePassword'),_c(VDivider)],1)],1)],1),_c(VCardActions,[_c('span',{staticClass:"ml-2 mb-2"},[_c('LanguageFlag')],1),(_vm.isAdmin())?_c(VBtn,{attrs:{"text":"","to":"Settings"}},[_c(VIcon,[_vm._v("mdi-cog")])],1):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.logout}},[_c(VIcon,[_vm._v("mdi-logout")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }