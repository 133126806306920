import { Rest } from "./Rest";

class WorkerNotification extends Rest {
  constructor() {
    super("worker-notification")
  }

  index(filter_params = [], relations = []) {
    return super.index(filter_params, relations)
  }

  toggleRead(id, params = []) {
    return super.update(id, params, "")
  }

  allRead() {
    return super.index([], [], "worker-notification/all-read")
  }
}

export { WorkerNotification };
