import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.selectedClass},[_c(VSelect,_vm._b({attrs:{"items":_vm.nations,"item-text":"name","item-value":"iso_2","label":_vm.label,"error-messages":_vm.error_message},on:{"change":_vm.selectedNation},scopedSlots:_vm._u([(_vm.show_flags)?{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"pr-1 pb-1"},[_c('country-flag',{attrs:{"country":item.iso_2}})],1),_vm._v(" +"+_vm._s(item.calling_code)+" ")]}}:null,{key:"item",fn:function({ item }){return [_c('country-flag',{attrs:{"country":item.iso_2}}),_c('span',{staticClass:"pt-2 pl-2"},[_vm._v(_vm._s(item.name))])]}},{key:"prepend-item",fn:function(){return [_c(VListItem,[_c(VListItemContent,[_c(VTextField,{attrs:{"autofocus":"","color":"primary","placeholder":_vm.$t('Search'),"clearable":"","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.searchNations},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(_vm.selected_nation),callback:function ($$v) {_vm.selected_nation=$$v},expression:"selected_nation"}},'v-select',this.$textFieldProps,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }