<template>
  <v-card flat>
    <v-card-subtitle class="d-flex justify-space-between">{{ $t('Change password') }} <v-btn color="green" icon
        @click="show_form = !show_form"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-card-subtitle>
    <v-card-text v-show="show_form">
      <AlertMessage ref="my_alert" />
      <v-row dense>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.current_password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
            :label="$t('Your current Password')" :hint="$t('password_condition')" @click:append="show1 = !show1"
            :error-messages="showErrors('current_password')" @blur="v$.user.current_password.$touch()"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" name="input-10-1"
            :label="$t('New Password')" :hint="$t('password_condition')" :error-messages="showErrors('password')"
            @blur="v$.user.password.$touch()" @click:append="show2 = !show2"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-bind="this.$textFieldProps" v-model="user.password_confirmation"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'" name="input-10-1"
            :label="$t('Repeat new Password')" :hint="$t('password_condition')"
            :error-messages="showErrors('password_confirmation')" @blur="v$.user.password_confirmation.$touch()"
            @click:append="show3 = !show3"></v-text-field>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn :disabled="v$.$invalid == 1 || loading" @click="changePassword()" color="orange" class="white--text"
            :loading="loading">
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { Profile } from "@/services/Profile";
//import AlertMessage from "@/components/page/AlertMessage.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, sameAs } from "@/locales/i18n-validators";
export default {
  name: "ChangePassword",
  components: {
    //AlertMessage,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      user: {
        current_password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(40),
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(40),
        },
        password_confirmation: { required, sameAs: sameAs(this.user.password) },
      },
    };
  },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show_form: false,
      user: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      loading: false,
    };
  },
  methods: {
    showErrors(name) {
      if (this.v$.user[name].$error) {
        return this.v$.user[name].$errors[0].$message;
      }
    },
    changePassword() {
      this.loading = true;
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        var p = new Profile();
        p.changePassword(this.user)
          .then((res) => {
            this.$refs.my_alert.showSuccess(
              res,
              this.$t('Password changed successfully')
            );
            this.user.current_password = "";
            this.user.password = "";
            this.user.password_confirmation = "";
            this.v$.$reset();
          })
          .catch((error) => {
            this.$refs.my_alert.showError(error, "");
          })
          .finally((this.loading = false));
      }
    },
  },
};
</script>
